<template>
	<frp-document-dialog :value="value"
				@update:value="$emit('update:value', $event)"
				max-width="1128"
				persistent>
		<template #content="{ onIntersect }">
			<div v-mutate="onIntersect" v-intersect="onIntersect">
				<vue-pdf-embed v-if="!isFileLoading" style="max-width: 1080px" :source="evaluationReportFileUrl"/>
				<v-progress-circular v-else class="my-4 mx-auto d-block" indeterminate></v-progress-circular>
			</div>
		</template>
		
		<template #footer>
			<div class="d-flex flex-column" style="width: 100%">
				<div v-if="!isFileLoading && user.evaluationReport.document.signatures.length"
					 class="d-flex flex-column" style="font-size: 12px; line-height: 16px; color: var(--v-blue-base)">
					<div v-for="signature in user.evaluationReport.document.signatures" class="d-flex align-center">
						<frp-icon class="mr-2" src="ico_signature-certificate"></frp-icon>
						<span class="mr-1">{{ formatFullName(signature.signer) }}</span>
						<span>{{ formatDate(convertToTimestamp(signature.createdAt), dateFormat) }}</span>
					</div>
				</div>
				
				<div class="d-flex align-center justify-end" style="gap: 8px">
					<frp-btn outlined
							 no-margin
							 @click="$emit('update:value', false)"
							 color="primary">
						{{ $t("buttons.close") }}
					</frp-btn>
					
					<frp-btn :disabled="isFileLoading"
							 v-if="user.evaluationReport?.document?.certificatesStampFileId"
							 @click="downloadSigned(user.evaluationReport.document.certificatesStampFileId)"
							 :loading="isSignedFileLoading"
							 elevation="0" color="blue" dark no-margin>
						{{ $t("buttons.downloadCertificatesStamp") }}
					</frp-btn>
					
					<frp-btn :disabled="isFileLoading"
							 @click="downloadOriginal"
							 elevation="0" color="blue" dark no-margin>
						{{ $t("buttons.downloadOriginal") }}
					</frp-btn>
					
					<frp-digital-signature-dialog :blob="evaluationReportFile"
												  @signed="handleSigned"
												  v-if="canSignEvaluationReport(user.userId)"
												  color="blue"
												  v-model="isSignDocumentDialogOpened">
						<template #activator="{ on, attrs }">
							<frp-btn elevation="0" color="blue" dark
									 :disabled="isFileLoading"
									 :loading="isSigning"
									 v-on="on"
									 v-bind="attrs">
								{{ $t("buttons.signWithDigitalSignatureAbbr") }}
							</frp-btn>
						</template>
					</frp-digital-signature-dialog>
				</div>
			</div>
		</template>
	</frp-document-dialog>
</template>

<script>
import FrpDocumentDialog from "@/components/dialogs/FrpDocumentDialog.vue";
import FrpDigitalSignatureDialog from "@/components/digitalSignature/FrpDigitalSignatureDialog.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import { Permissions } from "@/constants/permissions";
import { actionTypes, mutationTypes, getterTypes, namespace } from "@/store/kpi/modules/reporting/types";
import { convertToTimestamp, formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { formatFullName } from "@/utils/formatting";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpTextField from "Components/fields/FrpTextField";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	components: { FrpIcon, FrpDocumentDialog, FrpDigitalSignatureDialog, VuePdfEmbed, FrpBtn, FrpTextarea, FrpTextField, FrpDialog },
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		user: Object
	},
	data() {
		return {
			namespace,
            convertToTimestamp,
            formatFullName,
            formatDate,
            dateFormat,
			isSignDocumentDialogOpened: false
		};
	},
	computed: {
		...mapState({
            reportingPermissions: state => state.permissions,
			evaluationReportFile: state => state.evaluationReportFile,
			isFileLoading: state => state.isFileLoading,
			isSignedFileLoading: state => state.isSignedFileLoading,
			isSigning: state => state.isSigning
		}),
		...mapGetters({
			evaluationReportFileUrl: getterTypes.evaluationReportFileUrl
		})
	},
	methods: {
		...mapMutations({
			setEvaluationReportFile: mutationTypes.SET_EVALUATION_REPORT_FILE,
			setEvaluationReportFileMeta: mutationTypes.SET_EVALUATION_REPORT_FILE_META
		}),
		...mapActions({
			signScoreCard: actionTypes.signScoreCard,
			fetchSigningFile: actionTypes.fetchSigningFile,
			downloadOriginal: actionTypes.downloadOriginal,
			downloadSigned: actionTypes.downloadSigned
		}),
		async handleSigned(signature) {
			await this.signScoreCard({ ids: this.user.ids, signature });
		},
        canSignEvaluationReport(staffId) {
            return this.reportingPermissions.find(x => x.staffId === staffId)?.permissions
            .includes(Permissions.KPI_REPORT_EVALUATION_REPORT_SIGN);
        }
	},
	async created() {
		await this.fetchSigningFile(this.user.evaluationReport.document.fileId);
	},
	beforeDestroy() {
		this.setEvaluationReportFile();
		this.setEvaluationReportFileMeta();
	}
};
</script>
