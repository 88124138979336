<template>
	<div>
		<v-skeleton-loader height="12" max-width="100" type="image" class="mb-2 rounded-lg"/>
		<v-skeleton-loader height="40" type="image" class="mb-6 rounded-md"/>
	</div>
</template>

<script>
export default {
};
</script>

<style scoped>

</style>
