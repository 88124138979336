import { ApiReportKpi } from "@/api/kpi/types/apiReportKpi";
import { ApiLaborRate } from "@/api/kpi/types/apiLaborRate";
import { ApiKpiDepartment } from "@/api/kpi/types/apiKpiDepartment";
import { ApiKpiEvaluationReport } from "@/api/kpi/types/apiKpiEvaluationReport";
import { ApiEmployeePosition } from "@/api/kpi/types/apiEmployeePosition";
import { getIds } from "@/store/kpi/helpers/staffDepartmentPosition";
import { ApiKpiReportUser } from "@/api/kpi/types/apiKpiReportUser";

export interface KpiReportUser {
	id: string;
	firstName: string;
	lastName: string;
	middleName: string;
	email: string;
	position: ApiEmployeePosition;
	bonus: number;
	kpi: number;
	department: ApiKpiDepartment;
	laborRate: ApiLaborRate;
	testingRate: number;
	workingRate: number;
	kpis: ApiReportKpi[];
	evaluationReport?: ApiKpiEvaluationReport;
	ids: string;
}

export class KpiReportUserMapper {
	static map(source: ApiKpiReportUser): KpiReportUser {
		return {
			...source,
			ids: getIds(source.id, source.department.id, source.position.id)
		};
	}
}
