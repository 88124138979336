<template>
	<v-alert :color="alertBackgroundColor"
			 :class="`text-subtitle-2 frp-alert py-3 px-2 ${colorClass}`"
			 dense>
		<frp-icon :src="icon" class="mr-2"></frp-icon>
		<slot>
			{{ text }}
		</slot>
	</v-alert>
</template>

<script>
import alertMixin from "@/mixins/alertMixin";
import colorsMixin from "@/mixins/colorsMixin";
import FrpIcon from "Components/icon/FrpIcon";

export default {
	mixins: [colorsMixin, alertMixin],
	props: {
		text: String,
		type: {
			type: String,
			default: "info"
		},
		color: {
			type: String,
			default: ""
		}
	},
	computed: {
		alertBackgroundColor() {
			return this.color || this.backgroundColor;
		},
		icon() {
			return `ico_${this.type}`;
		}
	},
	components: { FrpIcon }
};
</script>

<style scoped lang="scss">
.frp-alert {
	width: fit-content;
}
</style>
