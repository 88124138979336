<template>
	<v-app>
		<v-app-bar height="52"
				   elevation="0"
				   class="flex-grow-0 kpi-app-bar"
				   dark
				   color="primary lighten-1">
			<v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click="drawer = !drawer"></v-app-bar-nav-icon>
			
			<v-list class="pa-0 kpi-menu" v-if="$vuetify.breakpoint.mdAndUp">
				<v-list-item class="px-8" v-for="(item, i) in menuItems"
							 :class="{ 'kpi-menu-active-item': item.childrenRoutes && item.childrenRoutes.includes($route.name) }"
							 :value="i"
							 :key="i"
							 :to="item.routeConfig"
							 :href="item.href">
					<v-list-item-title v-text="item.text"></v-list-item-title>
				</v-list-item>
			</v-list>

			<v-spacer></v-spacer>

			<v-menu bottom
					nudge-bottom="20"
					max-width="250"
					offset-y
					v-if="isInitialized && $vuetify.breakpoint.mdAndUp"
					content-class="elevation-5 menu-transition">
				<template v-slot:activator="{ on, attrs }">
					<v-btn max-width="300"
						   class="text-none profile-btn text-left justify-end user-menu pr-2 pl-5 text-subtitle-2 white--text"
						   style="border: none"
						   v-ripple="false"
						   v-bind="attrs"
						   v-on="on" text outlined>
						<span class="text-truncate">
							{{ `${user.firstName} ${user.lastName}` }}
						</span>
						<frp-icon class="ml-2" src="ico_bod-chevron-down" :color="colors.grey.lighten4"></frp-icon>
						<frp-icon class="ml-2" src="ico_help" :color="colors.warning.base"></frp-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click="logout">
						<v-icon :color="colors.primary.lighten1">mdi-logout</v-icon>
						<v-list-item-title class="ml-2">{{ $t("buttons.logout") }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>

		<router-view v-if="pageMode === PageModeType.OK" :key="$route.meta.key || $route.name"/>
		<frp-page-not-found v-else-if="pageMode === PageModeType.PAGE_NOT_FOUND"></frp-page-not-found>
		<frp-access-denied-error v-else-if="pageMode === PageModeType.ACCESS_DENIED"></frp-access-denied-error>
		<frp-alerts left-offset="0"></frp-alerts>
	</v-app>
</template>

<script>
import { externalUrls } from "@/constants/kpi/externalUrls";
import FrpAlerts from "Components/alerts/FrpAlerts";
import { findAllByAncestor } from "Router/kpi/routes";
import { PageModeType } from "Store/kpi/types/pageModeType";
import FrpPageNotFound from "Views/errors/FrpPageNotFound";
import FrpAccessDeniedError from "Views/errors/FrpAccessDeniedError";
import storeManager from "Store/manager";
import rootTypes from "Store/kpi/types";
import { mapState, mapMutations } from "vuex";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpIcon from "Components/icon/FrpIcon";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import { RouteNames } from "Router/kpi/routes";
import SecurityService from "Services/auth/securityService";
import { actionTypes, namespace } from "Store/kpi/modules/user/types";
import { createNamespacedHelpers } from "vuex";
import { getYear, getQuarter } from "date-fns";

const securityService = new SecurityService();
const { mapState: mapUserState, mapActions: mapUserActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, authorizationMixin],
	data() {
		return {
			storeManager,
			PageModeType,
			drawer: false
		};
	},
	computed: {
		...mapState({
			pageMode: state => state.pageMode
		}),
		...mapUserState({
			isInitialized: state => state.isInitialized,
			user: state => state.user
		}),
		otherModulesUrl() {
			return externalUrls.informationSystems;
		},
		hrProfileUrl() {
			return externalUrls.hrProfile;
		},
		menuItems() {
			const items = [
				{
					text: this.$t("navigation.kpi.hr"),
					permissions: [],
					href: this.hrProfileUrl
				},
				{
					text: this.$t("navigation.kpi.kpi"),
					permissions: [],
					routeConfig: { name: RouteNames.KPIS, params: { year: getYear(new Date()) } },
					childrenRoutes: findAllByAncestor(RouteNames.KPIS).map(x => x.model.name)
				},
				{
					text: this.$t("navigation.hr.support"),
					permissions: [],
					routeConfig: { name: RouteNames.SUPPORT }
				},
				{
					text: this.$t("navigation.hr.otherModules"),
					permissions: [],
					href: this.otherModulesUrl
				}
			];
			
			if(this.$route.name === RouteNames.REPORTING)
				items.splice(2, 0, {
					text: this.$t("navigation.kpi.reporting"),
					permissions: [],
					routeConfig: { name: RouteNames.REPORTING, params: { year: getYear(new Date()), quarter: getQuarter(new Date()) } },
					childrenRoutes: findAllByAncestor(RouteNames.REPORTING).map(x => x.model.name)
				});
			
			if(this.can(this.Permissions.KPI_DELEGACY_READ))
				items.splice(2, 0, {
					text: this.$t("navigation.kpi.delegacy"),
					permissions: [],
					routeConfig: { name: RouteNames.DELEGACY },
					childrenRoutes: findAllByAncestor(RouteNames.DELEGACY).map(x => x.model.name)
				});

			return items;
		}
	},
	methods: {
		...mapMutations({
			resetPageMode: rootTypes.mutationTypes.RESET_PAGE_MODE
		}),
		...mapUserActions({
			initializeStore: actionTypes.initialize
		}),
		logout() {
			this.signOut();
		}
	},
	async created() {
		if(!this.isInitialized)
			await this.initializeStore();
	},
	watch: {
		"$route"(to, from) {
			if(!to || !from)
				return;

			if(to.name !== from.name)
				this.resetPageMode();
		}
	},
	components: {
		FrpAlerts,
		FrpPageNotFound,
		FrpAccessDeniedError,
		FrpBtn,
		FrpIcon
	}
};
</script>

<style lang="scss">
.kpi-app-bar {
	.v-toolbar__content {
		padding: 0 16px 0 0;
	}
}

.kpi-menu {
	display: flex;
	background: none;
	height: 100%;

	a {
		padding: 0 20px !important;
		background-color: var(--v-primary-lighten1);
	}

	a::before {
		opacity: 0 !important;
	}
	
	.v-list-item--active,
	.kpi-menu-active-item {
		color: var(--v-white-base) !important;
		background-color: var(--v-blue-base);
	}
}
</style>
