import UserState from "@/store/kpi/modules/user/types/userState";
import { actionTypes, getterTypes, mutationTypes, namespace, relativeNamespace } from "@/store/kpi/modules/user/types";
import BaseMixinBuilder from "@/store/shared/base";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import SecurityService from "@/services/auth/securityService";
import AbortService from "@/services/abortService";
import { KpiController } from "@/api/kpi";
import { actionTypes as rootActionTypes } from "@/store/kpi/types";

const abortService = new AbortService();
const kpiController = new KpiController(abortService);

const securityService = new SecurityService();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = new UserState();

const getters = <GetterTree<UserState, any>>{};

const actions = <ActionTree<UserState, any>>{
	...baseMixin.actions,
	async [actionTypes.initialize]({ commit, dispatch }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.fetchUser);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetchUser]({ commit, dispatch }) {
		commit(mutationTypes.SET_IS_USER_LOADING, true);

		try {
			let user = await kpiController.getCurrentUser();

			commit(mutationTypes.SET_USER, user);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_USER_LOADING, false);
		}
	}
};

const mutations = <MutationTree<UserState>>{
	...baseMixin.mutations,
	[mutationTypes.SET_IS_USER_LOADING](state, value) {
		state.isUserLoading = value;
	},
	[mutationTypes.SET_USER](state, value) {
		state.user = value;
	}
};

export {
	namespace, state, actions, mutations, relativeNamespace, getters
};

const userModule = {
	namespace, state, actions, mutations, namespaced: true, relativeNamespace, getters
};

export default userModule;
