import { ApiKpiPeriod } from "@/api/kpi/types/apiKpiPeriod";
import { ApiStaffDepartmentPosition } from "@/api/kpi/types/apiStaffDepartmentPosition";
import { parseIds } from "@/store/kpi/helpers/staffDepartmentPosition";
import { AddKpiStaff } from "@/types/kpi/addKpiStaff";

export interface ApiAddKpiStaff {
	staffDepartmentPositionId: ApiStaffDepartmentPosition;
	period: ApiKpiPeriod;
	isChecked: boolean;
}

export class ApiAddKpiStaffHelper {
	static map(source: AddKpiStaff): ApiAddKpiStaff {
		return {
			staffDepartmentPositionId: parseIds(source.ids),
			period: source.period,
			isChecked: source.isChecked
		};
	}
}
