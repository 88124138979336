<template>
	<kpi-content-layout :title="title">
		<v-row>
			<v-col cols="12" md="6">
				<kpi-kpi-main-info-loader></kpi-kpi-main-info-loader>
			</v-col>
			<v-col cols="12" md="6" v-if="!isCreateMode">
				<kpi-kpi-criterias-info-loader></kpi-kpi-criterias-info-loader>
			</v-col>
		</v-row>
	</kpi-content-layout>
</template>

<script>

import KpiContentLayout from "@/components/layouts/KpiContentLayout.vue";
import { RouteNames } from "@/router/kpi/routes";
import KpiKpiCriteriasInfoLoader from "@/views/kpi/kpi/sections/criteriasInfo/KpiKpiCriteriasInfoLoader.vue";
import KpiKpiMainInfoLoader from "@/views/kpi/kpi/sections/mainInfo/KpiKpiMainInfoLoader.vue";
import { createNamespacedHelpers } from "vuex";
import { namespace } from "@/store/kpi/modules/kpi";

const { mapState, mapMutations, mapActions, mapGetters } = createNamespacedHelpers(namespace);

export default {
	metaInfo() {
		return { title: this.title };
	},
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		title() {
			switch (this.$route.name) {
				case RouteNames.KPI:
					return this.$t("metaTitles.kpiCard");
				case RouteNames.KPI_CREATE:
					return this.$t("metaTitles.kpiCardCreate");
			}
		},
		isCreateMode() {
			return !this.$route.params.id;
		}
	},
	components: {
		KpiKpiCriteriasInfoLoader,
		KpiKpiMainInfoLoader,
		KpiContentLayout
	}
};
</script>

<style scoped lang="scss">

</style>