import IPageState from "@/store/shared/base/types/pageState";
import FormState from "@/store/shared/form/models/formState";
import { KpiMainInfo, KpiMainInfoHelper } from "@/store/kpi/modules/kpi/modules/mainInfo/types/kpiMainInfo";
import { KpiMainInfoModeTypeEnum } from "@/store/kpi/modules/kpi/modules/mainInfo/types/KpiMainInfoModeTypeEnum";
import { KpiMatrixItem } from "@/types/kpi/kpiMatrixItem";

export default class KpiMainInfoState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public id: string = "",
		public mode: KpiMainInfoModeTypeEnum = KpiMainInfoModeTypeEnum.READ,
		public isKpiMainInfoFormSaving: boolean = false,
		public isKpiDeleting: boolean = false,
		public isKpiMainInfoFormValid: boolean = false,
		public isMatrixLoading: boolean = false,
		public matrix: KpiMatrixItem[] = [],
		public editableItem: KpiMainInfo = KpiMainInfoHelper.getEmpty()
	)
	{
	}
}
