import IPageState from "@/store/shared/base/types/pageState";
import FormState from "@/store/shared/form/models/formState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import { KpiCriteriasModeTypeEnum } from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/KpiCriteriasModeTypeEnum";
import { KpiCriteriasItem } from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/kpiCriteriasItem";
import KpiCriteriasFilter from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/kpiCriteriasFilter";
import { KpiPeriod } from "@/types/kpi/KpiPeriod";
import RouteState from "@/store/shared/route/types/routeState";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";

export default class KpiCriteriasInfoState implements IPageState {
	constructor(
		public listing: ListingModel<KpiCriteriasItem>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public route: RouteState,
		public form: FormState,
		public snapshot: object,
		public filter: KpiCriteriasFilter = new KpiCriteriasFilter(),
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public id: string = "",
		public mode: KpiCriteriasModeTypeEnum = KpiCriteriasModeTypeEnum.READ,
		public editableItems: KpiCriteriasItem[] = [],
		public staffKpisPeriods: KpiPeriod[] = [],
		public isKpiCriteriasFormSaving: boolean = false,
		public isKpiCriteriasFormValid: boolean = false
	)
	{
	}
}
