import { Permissions } from "@/constants/permissions";
import { getIds } from "@/store/kpi/helpers/staffDepartmentPosition";
import { ApiReportingStaffPermissions } from "@/api/kpi/types/apiReportingStaffPermissions";

export interface ReportingStaffPermissions {
	ids: string;
	staffId: string;
	departmentId: string;
	positionId: string;
	permissions: Permissions[];
}

export class ReportingStaffPermissionsMapper {
	static map(source: ApiReportingStaffPermissions): ReportingStaffPermissions {
		return {
			...source,
			ids: getIds(source.staffId, source.departmentId, source.positionId)
	};
	}
}
