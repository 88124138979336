import SubscribersManager from "@/store/manager/subscribersManager";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/kpi/modules/delegacy/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import { Store } from "vuex";
import AbortService from "@/services/abortService";
import { KpiController } from "@/api/kpi";
import { RouteNames } from "@/router/kpi/routes";
import router from "@/router/kpi";
import routeTypes from "@/store/shared/route/types";
import { formatFullName, formatFullNameWithInitials } from "@/utils/formatting";
import { actionTypes as rootActionTypes } from "@/store/kpi/types";
import DelegacyRouteQuery from "@/store/kpi/modules/delegacy/types/delegacyRouteQuery";
import { DelegacyStatusEnum } from "@/types/kpi/delegacyStatusEnum";
import DelegacyRouteService from "@/store/kpi/modules/delegacy/services/delegacyRouteService";
import DelegacyState from "@/store/kpi/modules/delegacy/types/delegacyState";
import DelegacyFilter from "@/store/kpi/modules/delegacy/types/delegacyFilter";
import DelegacyMapper from "@/store/kpi/modules/delegacy/mapper";
import { refreshRoute } from "@/utils/router";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { DelegacyItem, DelegacyItemMapper } from "@/types/kpi/delegacy";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";

const abortService = new AbortService();
const kpiController = new KpiController(abortService);

const defaultRouteQuery = new DelegacyRouteQuery("", "", DelegacyStatusEnum.ACTIVE);

const routeService = new DelegacyRouteService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<DelegacyState>()).build();

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["createDelegacyRequest"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new DelegacyState(
			new ListingModel<DelegacyItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			new DelegacyFilter(),
			routeMixin.state(),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<DelegacyState>;

const getters = <GetterTree<DelegacyState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.formattedItems]: (state, getters) => {
		return state.listing.items.map(x => {
			return {
				...x,
				date: formatDate(x.date, dateFormat),
				department: x.appointer.department.name,
				appointer: formatFullName(x.appointer),
				attorney: formatFullName(x.attorney),
				attorneyWithInitials: formatFullNameWithInitials(x.attorney)
			};
		});
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<DelegacyState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<DelegacyState>(rootState, namespace);
	
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_APPOINTER_ID):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_DEPARTMENT_ID):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_STATUS):
		case resolveMutation(namespace, mutationTypes.RESET_FILTER):
			await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			
			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});
			
			break;
		}
	}
};

const actions = <ActionTree<DelegacyState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.processRoute);
		await dispatch(actionTypes.reconstituteRoute);
		
		await dispatch(actionTypes.updateListingItems);
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.updateListingItems]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			let { delegacies } = await kpiController.getDelegacyRegistry(DelegacyMapper.mapToApiGetDelegacyParameters(state));
			
			commit(mutationTypes.SET_LISTING_ITEMS, delegacies.map(x => DelegacyItemMapper.map(x)));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRoute]({ rootState, commit, dispatch, state }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);
		
		let routeQuery = await routeService.resolveRouteQuery(rootState.route.query);
		
		// Если при открытии страницы уже стоят фильтры, нужно сразу загрузить соответствующие справочники
		if(routeQuery.departmentId)
			await dispatch(actionTypes.fetchDepartments);
		if(routeQuery.appointerId)
			await dispatch(actionTypes.fetchAppointers);
		
		commit(mutationTypes.SET_FILTER_APPOINTER_ID, routeQuery.appointerId);
		commit(mutationTypes.SET_FILTER_DEPARTMENT_ID, routeQuery.departmentId);
		commit(mutationTypes.SET_FILTER_STATUS, routeQuery.status);
		
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.push({
			name: RouteNames.DELEGACY,
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.replace({
			name: RouteNames.DELEGACY,
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetchAppointers]({ commit, state, dispatch }) {
		if(state.appointers.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_APPOINTERS_LOADING, true);
		
		try {
			const items = await kpiController.getDelegacyAppointers();
			
			const users = items.map(x => ({ ...x, fullName: formatFullName(x) }));
			
			commit(mutationTypes.SET_APPOINTERS, users.sort((a, b) => a.fullName.localeCompare(b.fullName)));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_APPOINTERS_LOADING, false);
		}
	},
	async [actionTypes.fetchAttorneys]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_ATTORNEYS_LOADING, true);

		try {
			const items = await kpiController.getDelegacyAttorneys(state.createDelegacyRequest.appointerStaffId);

			const users = items.map(x => ({ ...x, fullName: formatFullName(x) }));

			commit(mutationTypes.SET_ATTORNEYS, users.sort((a, b) => a.fullName.localeCompare(b.fullName)));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_ATTORNEYS_LOADING, false);
		}
	},
	async [actionTypes.fetchDepartments]({ commit, state, dispatch }) {
		if(state.departments.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, true);
		
		try {
			const items = await kpiController.getDepartments();
			
			commit(mutationTypes.SET_DEPARTMENTS, items);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, false);
		}
	},
	async [actionTypes.addDelegacy]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		
		try {
			await kpiController.createDelegacy(state.createDelegacyRequest);
			
			alertService.addInfo(AlertKeys.SUCCESS_CREATED_INFO);
			
			await refreshRoute(router);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.deleteDelegacy]({ commit, dispatch, state }, id: string) {
		commit(mutationTypes.SET_IS_DELEGACY_DELETING, true);
		
		try {
			await kpiController.deleteDelegacy(id);
			
			alertService.addInfo(AlertKeys.KPI_DELEGACY_SUCCESS_DELETED);
			
			commit(mutationTypes.SET_LISTING_ITEMS, state.listing.items.filter(x => x.id !== id));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_DELEGACY_DELETING, false);
		}
	}
};

const mutations = <MutationTree<DelegacyState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_FILTER_APPOINTER_ID](state, value) {
		state.filter.appointerId = value;
	},
	[mutationTypes.SET_FILTER_DEPARTMENT_ID](state, value) {
		state.filter.departmentId = value;
	},
	[mutationTypes.SET_FILTER_STATUS](state, value) {
		state.filter.status = value;
	},
	[mutationTypes.RESET_FILTER](state) {
		state.filter = new DelegacyFilter();
	},
	[mutationTypes.SET_IS_APPOINTERS_LOADING](state, value) {
		state.isAppointersLoading = value;
	},
	[mutationTypes.SET_IS_ATTORNEYS_LOADING](state, value) {
		state.isAttorneysLoading = value;
	},
	[mutationTypes.SET_IS_DEPARTMENTS_LOADING](state, value) {
		state.isDepartmentsLoading = value;
	},
	[mutationTypes.SET_IS_DELEGACY_DELETING](state, value) {
		state.isDelegacyDeleting = value;
	},
	[mutationTypes.SET_APPOINTERS](state, value) {
		state.appointers = value;
	},
	[mutationTypes.SET_ATTORNEYS](state, value) {
		state.attorneys = value;
	},
	[mutationTypes.SET_DEPARTMENTS](state, value) {
		state.departments = value;
	},
	[mutationTypes.SET_CREATE_DELEGACY_REQUEST_APPOINTER_ID](state, value) {
		state.createDelegacyRequest.appointerStaffId = value;
	},
	[mutationTypes.SET_CREATE_DELEGACY_REQUEST_ATTORNEY_ID](state, value) {
		state.createDelegacyRequest.attorneyStaffId = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const delegacyModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default delegacyModule;
