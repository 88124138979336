import { IsArray, IsNumber, IsString } from "class-validator";
import { getQuarter } from "date-fns";
import { KpiQuarterEnum } from "@/types/kpi/KpiQuarterEnum";

export default class ReportingFilter {
	@IsNumber()
	year: number;
	
	quarter: KpiQuarterEnum | null;
	
	@IsString()
	kpiId: string;
	
	@IsArray()
	staffIds: string[];
	
	constructor(
		year: number = 0,
		quarter: KpiQuarterEnum | null = String(getQuarter(new Date())) as KpiQuarterEnum,
		kpiId: string = "",
		staffIds: string[] = []
	)
	{
		this.year = year;
		this.quarter = quarter;
		this.kpiId = kpiId;
		this.staffIds = staffIds;
	}
}
