import { ApiKpiPeriod } from "@/api/kpi/types/apiKpiPeriod";
import { ApiKpiMatrixItem } from "@/api/kpi/types/apiKpiMatrixItem";
import { getIds } from "@/store/kpi/helpers/staffDepartmentPosition";
import { ApiKpiUser } from "@/api/kpi/types/apiKpiUser";

export interface KpiMatrixItem {
	ids: string;
	staff: ApiKpiUser;
	period: ApiKpiPeriod;
	isChecked: boolean;
}

export class KpiMatrixMapper {
	static map(source: ApiKpiMatrixItem): KpiMatrixItem {
		return {
			ids: getIds(source.staffDepartmentPosition.id,
				source.staffDepartmentPosition.department.id,
				source.staffDepartmentPosition.position.id),
			staff: source.staffDepartmentPosition,
			period: { year: source.period.year, quarter: source.period.quarter ?? null },
			isChecked: false
		};
	}
}
