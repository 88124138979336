<template>
	<v-switch
		:loading="loading"
		:readonly="loading"
		class="frp-switch"
		:class="{ 'mt-0': $vuetify.breakpoint.xsOnly }"
		inset
		dense
		:disabled="disabled"
		:input-value="value"
		@change="$emit('update:value', $event)"
		hide-details>
		<template #label>
			<span class="text-body-2 primary--text text--darken-1">{{ label }}</span>
		</template>
	</v-switch>
</template>

<script>
export default {
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		label: {
			type: String,
			required: true
		},
		loading: Boolean,
		value: Boolean,
		disabled: Boolean
	}
};
</script>

<style scoped>

</style>
