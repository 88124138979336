import { formTypes } from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import { SavingType } from "@/types/SavingType";
import { mapInstanceState, mapInstanceMutations, mapInstanceGetters } from "@/utils/vuexMapInstanse";
import { resolveAction } from "@/utils/vuexModules";
import FormState from "@/store/shared/form/models/formState";
import Vue from "vue";

type StateType = { form: FormState };

const formMixin = Vue.extend({
	data() {
		return {
			refs: {
				form: "form"
			},
			formValid: true
		};
	},
	computed: {
		isCreateMode() {
			return !this.$route.params.id;
		},
		...mapInstanceGetters({
			isStateContainsUnsavedChanges: snapshotMixinTypes.getterTypes.stateContainsUnsavedChanges
		}),
		...mapInstanceState({
			isFormSaving: (state: StateType) => state.form.isSaving,
			isRecordUnique: (state: StateType) => state.form.isRecordUnique,
			isRecordUniqueCheckInProgress: (state: StateType) => state.form.isRecordUniqueCheckInProgress,
			isFormReadonly: (state: StateType) => {
				return state.form.isReadonly || state.form.isDisabled || state.form.isLoadingState || state.form.isSaving;
			},
			isFormDisabled: (state: StateType) => state.form.isDisabled,
			isFormValid: (state: StateType) => state.form.isValid,
			isFormLoadingState: (state: StateType) => state.form.isLoadingState
		}),
		stateContainsUnsavedChanges() {
			return this.isStateContainsUnsavedChanges();
		}
	},
	methods: {
		...mapInstanceMutations({
			setIsFormValid: formTypes.mutationTypes.SET_IS_FORM_VALID,
			setIsFormDisabled: formTypes.mutationTypes.SET_IS_FORM_DISABLED
		}),
		async onFormCancel() {
			await this.cancelChanges();
			this.$nextTick(() => {
				if(!this.isResetValidationDisabled)
					this.$refs[this.refs.form].resetValidation();
			});
		},
		async cancelChanges() {
			await this.$store.dispatch(resolveAction(this.namespace, snapshotMixinTypes.actionTypes.cancelChanges));
		},
		validate() {
			return this.$refs[this.refs.form].validate();
		},
		async submit() {
			if(this.validate()) {
				try {
					this.isCreateMode
						? await this.save({ type: SavingType.CREATE })
						: await this.save({ type: SavingType.UPDATE, id: this.$route.params.id });
				} catch (e) {
					console.error(e);
					// Обработка производится на уровне store
				}
			}
		}
	},
	watch: {
		formValid: {
			handler(value) {
				this.setIsFormValid(value);
			},
			immediate: true
		}
	}
});

export default formMixin;
