import { KpiPeriodMapper } from "@/types/kpi/KpiPeriod";
import { StaffKpi } from "@/types/kpi/staffKpi";
import { ApiStaffKpiCriteria } from "@/api/kpi/types/apiStaffKpiCriteria";

export class KpiCriteriasMapper {
	static mapToStaffKpi(source: ApiStaffKpiCriteria): StaffKpi {
		return {
			...source,
			period: KpiPeriodMapper.map(source.period)
		};
	}
}