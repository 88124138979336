import { ApiGetDelegacyParameters } from "@/api/kpi/types/apiGetDelegacyParameters";
import DelegacyState from "@/store/kpi/modules/delegacy/types/delegacyState";
import { DelegacyStatusEnum } from "@/types/kpi/delegacyStatusEnum";

export default class DelegacyMapper {
	static mapToApiGetDelegacyParameters(source: DelegacyState): ApiGetDelegacyParameters {
		return {
			departmentId: source.filter.departmentId,
			appointerId: source.filter.appointerId,
			activeOnly: source.filter.status === DelegacyStatusEnum.ACTIVE,
			deletedOnly: source.filter.status === DelegacyStatusEnum.NOT_ACTIVE
		};
	}
}
