import { IsEnum, IsString } from "class-validator";
import { DelegacyStatusEnum } from "@/types/kpi/delegacyStatusEnum";

export default class DelegacyRouteQuery {
	@IsString()
	departmentId: string;
	
	@IsString()
	appointerId: string;
	
	@IsString()
	@IsEnum(DelegacyStatusEnum)
	status: DelegacyStatusEnum;
	
	constructor(
		departmentId: string = "",
		appointerId: string = "",
		status: DelegacyStatusEnum = DelegacyStatusEnum.ALL
	)
	{
		this.departmentId = departmentId;
		this.appointerId = appointerId;
		this.status = status;
	}
}
