import Middleware from "@/router/middleware/middleware";
import { NavigationGuard, NavigationGuardNext, Route, VueRouter } from "vue-router/types/router";
import RedirectMiddleware from "@/router/kpi/middleware/items/redirectMiddleware";
import PermissionsMiddleware from "@/router/kpi/middleware/items/permissionsMiddleware";
import AuthenticationMiddleware from "@/router/kpi/middleware/items/authenticationMiddleware";

export const configureMiddleware = (router: VueRouter) => {
	router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
		const middleware = new Middleware(to, from, next);

		const publicRoute = to.matched.some(record => record.meta.public);

		if(!publicRoute) {
			middleware.useMiddleware(AuthenticationMiddleware);
			middleware.useMiddleware(RedirectMiddleware);
			middleware.useMiddleware(PermissionsMiddleware);
		}

		await middleware.run();
	});
};
