import { pagingMutationTypes, pagingActionTypes } from "@/store/shared/paging/types";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import { MutationTree, ActionTree } from "vuex";

export default class PagingMixinBuilder {
	constructor() {
	}

	build() {
		return {
			mutations: <MutationTree<{ paging: PagingModel }>>{
				[pagingMutationTypes.SET_PAGING_TOTAL](state, value) {
					state.paging.total = value;
				},
				[pagingMutationTypes.SET_PAGING_PAGE_SIZE](state, value) {
					state.paging.pageSize = value;
				},
				[pagingMutationTypes.SET_PAGING_PAGE](state, value) {
					state.paging.page = value;
				}
			},
			actions: <ActionTree<PagingModel, any>>{
				async [pagingActionTypes.setPagingPageSize]({ commit, dispatch }, value) {
					commit(pagingMutationTypes.SET_PAGING_PAGE, 1);
					commit(pagingMutationTypes.SET_PAGING_PAGE_SIZE, value);
				},
				async [pagingActionTypes.setPagingPage]({ dispatch, commit }, page) {
					commit(pagingMutationTypes.SET_PAGING_PAGE, page);
				},
				async [pagingActionTypes.resetPagingPage]({ commit }) {
					commit(pagingMutationTypes.SET_PAGING_PAGE, 1);
				}
			}
		};
	}
}
