<template>
	<v-row class="frp-form-card-blocks">
		<slot></slot>
	</v-row>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.frp-form-card-blocks {
	& + & {
		margin-top: 40px !important;
	}
}
</style>
