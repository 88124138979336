import { Criteria } from "@/store/kpi/modules/kpi/types/criteria";
import { KpiPeriod } from "@/types/kpi/KpiPeriod";
import { AddKpiStaff } from "@/types/kpi/addKpiStaff";

export interface KpiMainInfo {
	title: string;
	methodology: string;
	criterias: Criteria[];
	kpiStaffs: AddKpiStaff[];
	periods: KpiPeriod[];
	isRegular: boolean;
	isActive: boolean;
}

export class KpiMainInfoHelper {
	static getEmpty(): KpiMainInfo {
		return {
			title: "",
			methodology: "",
			criterias: [],
			kpiStaffs: [],
			periods: [],
			isRegular: false,
			isActive: true
		};
	}
}
