import { IsNumber } from "class-validator";
import { KpiQuarterEnum } from "@/types/kpi/KpiQuarterEnum";

export default class ReportingRouteParams {
	@IsNumber()
	year: number;

	quarter: KpiQuarterEnum | null;
	
	constructor(
		year: number = 0,
		quarter: KpiQuarterEnum | null = null
	)
	{
		this.year = year;
		this.quarter = quarter;
	}
}
