export default class FormState {
	isLoadingState: boolean;
	isSaving: boolean;
	isDisabled: boolean;
	isValid: boolean;
	isInitialized: boolean;
	isReadonly: boolean;
	isRecordUnique: boolean;
	isRecordUniqueCheckInProgress: boolean;

	constructor(isLoadingState: boolean,
		isSaving: boolean,
		isDisabled: boolean,
		isValid: boolean,
		isInitialized: boolean,
		isReadonly: boolean,
		isRecordUnique: boolean,
		isRecordUniqueCheckInProgress: boolean)
	{
		this.isLoadingState = isLoadingState;
		this.isSaving = isSaving;
		this.isDisabled = isDisabled;
		this.isValid = isValid;
		this.isInitialized = isInitialized;
		this.isReadonly = isReadonly;
		this.isRecordUnique = isRecordUnique;
		this.isRecordUniqueCheckInProgress = isRecordUniqueCheckInProgress;
	}
}
