<template>
	<frp-dialog :value="value"
				@update:value="$emit('update:value', $event)"
				max-width="496"
				:title="$t('dialogs.kpiDownloadScoreCards.title')"
				persistent>
		<template #content="{ onIntersect }">
			<v-form ref="form" v-intersect="onIntersect" v-model="formValid">
				<bod-autocomplete item-text="year"
								  item-value="year"
								  class="kpi-field" color="blue"
								  required
								  :items="uniqBy(periods, x => x.year)"
								  v-model="year"
								  :label="$t('fields.year.label')"
								  :placeholder="$t('fields.year.placeholder')">
				</bod-autocomplete>
				<bod-autocomplete item-text="text"
								  item-value="value"
								  class="kpi-field" color="blue"
								  hide-details
								  required
								  :items="quarters"
								  v-model="quarter"
								  :label="$t('fields.period.label')"
								  :placeholder="$t('fields.period.placeholder')">
				</bod-autocomplete>
			</v-form>
		</template>

		<template #footer>
			<frp-btn outlined color="primary"
					 @click="$emit('update:value', false)">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0" color="blue" dark
					 :loading="isScoreCardsDownloading"
					 :disabled="!formValid"
					 @click="handleSave">
				{{ $t("buttons.download") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import BodAutocomplete from "@/components/fields/BodAutocomplete.vue";
import KpiContentLayout from "@/components/layouts/KpiContentLayout.vue";
import { namespace } from "@/store/kpi/modules/reporting";
import { actionTypes, mutationTypes } from "@/store/kpi/modules/reporting/types";
import { KpiQuarterEnum } from "@/types/kpi/KpiQuarterEnum";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpTextField from "Components/fields/FrpTextField";
import { uniqBy } from "lodash";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	components: { KpiContentLayout, BodAutocomplete, FrpBtn, FrpTextarea, FrpTextField, FrpDialog },
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			uniqBy,
			namespace,
			formValid: false
		};
	},
	computed: {
		...mapState({
			downloadScoreCardsRequest: state => state.downloadScoreCardsRequest,
			isScoreCardsDownloading: state => state.isScoreCardsDownloading,
			periods: state => state.periods
		}),
		year: {
			get() {
				return this.downloadScoreCardsRequest.year;
			},
			set(value) {
				this.setDownloadScoreCardsRequestYear(value);
			}
		},
		quarter: {
			get() {
				return this.downloadScoreCardsRequest.period;
			},
			set(value) {
				this.setDownloadScoreCardsRequestPeriod(value);
			}
		},
		quarters() {
			return [
				...Object.values(KpiQuarterEnum).map(x => (
					{
						text: this.$t(`aliases.kpiFullQuarter.${x}`),
						value: x
					}
				))
			];
		}
	},
	methods: {
		async handleSave() {
			await this.downloadScoreCards();
			this.$emit("update:value", false);
		},
		...mapMutations({
			setDownloadScoreCardsRequestYear: mutationTypes.SET_DOWNLOAD_SCORE_CARDS_REQUEST_YEAR,
			setDownloadScoreCardsRequestPeriod: mutationTypes.SET_DOWNLOAD_SCORE_CARDS_REQUEST_PERIOD
		}),
		...mapActions({
			downloadScoreCards: actionTypes.downloadScoreCards
		})
	},
	async mounted() {
		this.year = +this.$route.params.year;
		this.quarter = null;

		await this.$nextTick();

		this.$refs.form.resetValidation();
	}
};
</script>
