<template>
	<v-checkbox :input-value="value"
				@change="$emit('update:value', $event)"
				hide-details
				:true-value="trueValue"
				:disabled="disabled"
				:false-value="falseValue"
				:value="value"
				:color="color">
		<template #label>
				<span class="text-body-1" :class="labelClass">
					{{ text }}
				</span>
		</template>
	</v-checkbox>
</template>

<script>
export default {
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: [String, Boolean],
		labelClass: {
			type: String,
			default: "primary--text text--darken-1 pl-2"
		},
		text: String,
		color: String,
		disabled: Boolean,
		trueValue: {
			type: [String, Boolean],
			default: true
		},
		falseValue: {
			type: [String, Boolean],
			default: false
		}
	}
};
</script>
