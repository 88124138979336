import { Criteria } from "@/store/kpi/modules/kpi/types/criteria";

export interface ApiCriteria {
	id: string;
	title: string;
	sort: number;
}

export class ApiCriteriaMapper {
	static map(source: Criteria): ApiCriteria {
		return {
			id: source.id,
			title: source.title,
			sort: source.sort
		};
	}
}