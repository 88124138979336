<template>
	<v-row>
		<slot></slot>
	</v-row>
</template>

<script>
export default {};
</script>

<style scoped>

</style>
