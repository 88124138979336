<template>
	<v-card class="kpi-card pb-7 px-0 mt-7">
		<v-row>
			<v-col class="d-flex align-start px-8 pb-4 pt-6">
				<v-skeleton-loader height="40" width="231" type="image" class="rounded-md"/>
			</v-col>
		</v-row>
		
		<v-row class="px-3">
			<v-col v-for="(item, i) in 3" :key="i" class="d-flex justify-center pb-0">
				<v-skeleton-loader type="image" width="100" height="16"/>
			</v-col>
		</v-row>
		
		<v-row class="px-0">
			<v-col>
				<v-data-table
					loading
					hide-default-footer
					:loading-text="$t('tables.loading')">
				</v-data-table>
			</v-col>
		</v-row>
		
		<div class="d-flex mx-n3 mt-10" style="padding-right: 69px;">
			<v-spacer></v-spacer>
			
			<frp-btn-loader width="156" height="40"></frp-btn-loader>
		</div>
	</v-card>
</template>

<script>
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader.vue";

export default {
	components: { FrpTextBtn, FrpBtnLoader, FrpCard }
};
</script>

<style lang="scss">
</style>