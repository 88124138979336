<template>
	<v-dialog value="true" persistent width="512">
		<v-card>
			<v-card-title class="headline">{{ title }}</v-card-title>
			<v-card-text class="text-justify">{{ description }}</v-card-text>
			<v-card-actions class="pa-6">
				<v-spacer></v-spacer>
				<frp-btn color="primary"
						 outlined
						 @click="cancel">
					{{ cancelBtn || $t("buttons.back") }}
				</frp-btn>
				<frp-btn :color="submitBtnColor" :dark="submitBtnDark" @click="submit">
					{{ submitBtn || $t("buttons.ok") }}
				</frp-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";

export default {
	components: { FrpBtn },
	props: {
		title: String,
		description: String,
		cancelBtn: String,
		submitBtn: String,
		submitBtnColor: {
			type: String,
			default: "secondary"
		},
		submitBtnDark: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		submit() {
			this.$emit("submit");
		},
		cancel() {
			this.$emit("cancel");
		}
	}
};
</script>

<style scoped>

</style>
