<template>
	<kpi-content-layout container-class="pt-1 pt-lg-2 px-4 px-lg-4" bg-height="112">
		<script data-b24-form="inline/89/by9jj8" data-skip-moving="true" type="application/javascript">
			(function (w, d, u) {
				var s = d.createElement("script");
				s.async = true;
				s.src = u + "?" + (Date.now() / 180000 | 0);
				var h = d.getElementsByTagName("script")[0];
				h.parentNode.insertBefore(s, h);
			})(window, document, "https://portal.frprf.ru/upload/crm/form/loader_89_by9jj8.js");
		</script>
	</kpi-content-layout>
</template>

<script>

import KpiContentLayout from "@/components/layouts/KpiContentLayout.vue";
import { defineComponent } from "vue";

export default defineComponent({
	components: { KpiContentLayout }
});
</script>

