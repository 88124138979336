<template>
	<v-main>
		<v-container fluid
					 class="py-1 px-5 white darken-2 align-start justify-start"
					 fill-height>
			<loan-card-layout>
				<template #content>
					<v-container class="fill-height align-start pa-0" fluid>
						<v-row>
							<v-col>
								<slot></slot>
							</v-col>
						</v-row>
					</v-container>
				</template>
			</loan-card-layout>
		</v-container>
	</v-main>
</template>

<script>
import LoanCardLayout from "Components/layouts/LoanCardLayout";

export default {
	components: {
		LoanCardLayout
	}
};
</script>
