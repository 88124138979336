import { ApiKpi } from "@/api/kpi/types/apiKpi";
import { KpiMainInfo } from "@/store/kpi/modules/kpi/modules/mainInfo/types/kpiMainInfo";
import { CriteriaHelper } from "@/store/kpi/modules/kpi/types/criteria";
import { KpiPeriodMapper } from "@/types/kpi/KpiPeriod";
import { AddKpiStaffHelper } from "@/types/kpi/addKpiStaff";

export class KpiMainInfoMapper {
	mapToKpiMainInfo(source: ApiKpi): KpiMainInfo {
		return {
			...source,
			methodology: source.methodology ?? "",
			periods: source.periods.map(x => KpiPeriodMapper.map(x)),
			criterias: source.criterias.sort((a, b) => a.sort - b.sort).map((x, index) => CriteriaHelper.map(x, index)),
			kpiStaffs: source.staffKpis.map(x => AddKpiStaffHelper.mapFromApiStaffKpi(x))
		};
	}
}