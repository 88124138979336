<template>
	<v-pagination v-if="length"
				  @input="updateValue"
				  :total-visible="totalVisible"
				  :color="activeColor"
				  :disabled="disabled"
				  :class="{ 'pagination-default-color': !activeColor }"
				  :value="value"
				  :length="length"
	></v-pagination>
</template>

<script>
import colorsMixin from "Mixins/colorsMixin";

export default {
	model: {
		prop: "value",
		event: "update:value"
	},
	mixins: [colorsMixin],
	props: {
		value: {
			type: Number,
			required: true
		},
		length: {
			type: Number,
			required: true
		},
		totalVisible: {
			type: Number,
			default: 7
		},
		activeColor: String,
		disabled: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		updateValue(val) {
			if(val !== this.value)
				this.$emit("update:value", val);
		}
	},
	components: {}
};
</script>

<style lang="scss">
.theme--light.v-pagination .v-pagination__item.v-pagination__item--active {
	cursor: initial;
	box-shadow: none;
}

.pagination-default-color .v-pagination__item.v-pagination__item--active {
	background-color: transparent !important;
	color: var(--v-white-darken5) !important;
}
</style>
