<template>
	<v-card class="kpi-card pa-9 pb-6 my-4">
		<frp-form-card-blocks class="d-flex flex-column mb-6">
			<frp-form-card-block-row>
				<frp-form-card-block-col style="flex: 0 0 30%">
					<frp-custom-title-loader width="100" height="14" class="mt-1"></frp-custom-title-loader>
				</frp-form-card-block-col>
				
				<frp-form-card-block-col>
					<frp-textarea-loader :label="false" textarea-height="78"></frp-textarea-loader>
				</frp-form-card-block-col>
			</frp-form-card-block-row>
			
			<frp-form-card-block-row class="mb-3">
				<frp-form-card-block-col style="flex: 0 0 30%" class="d-flex align-center">
					<v-skeleton-loader type="text" width="80" height="14"/>
				</frp-form-card-block-col>
				
				<frp-form-card-block-col class="pr-3 pt-2">
					<div class="d-flex flex-column" style="gap: 12px">
						<div class="d-flex align-center justify-space-between" v-for="(item, i) in 2" :key="i">
							<v-skeleton-loader height="57" style="width: 100%;" type="image" class="rounded-md"/>
						</div>
					</div>
				</frp-form-card-block-col>
			</frp-form-card-block-row>
			
			<frp-form-card-block-row class="mb-3">
				<frp-form-card-block-col style="flex: 0 0 30%" class="d-flex align-center">
					<v-skeleton-loader type="text" width="160" height="14"/>
				</frp-form-card-block-col>
				
				<frp-form-card-block-col class="d-flex align-center">
					<v-skeleton-loader height="16" width="40" type="image" class="rounded-md"/>
				</frp-form-card-block-col>
			</frp-form-card-block-row>
			
			<frp-form-card-block-row>
				<frp-form-card-block-col style="flex: 0 0 30%">
					<v-skeleton-loader type="text" width="160" height="14"/>
				</frp-form-card-block-col>
				
				<frp-form-card-block-col>
					<v-skeleton-loader height="162" style="width: 100%;" type="image" class="rounded-md"/>
				</frp-form-card-block-col>
			</frp-form-card-block-row>
			
			<frp-form-card-block-row>
				<frp-form-card-block-col style="flex: 0 0 30%" class="d-flex align-center">
					<v-skeleton-loader type="text" width="160" height="14"/>
				</frp-form-card-block-col>
				
				<frp-form-card-block-col>
					<v-skeleton-loader height="89" style="width: 100%;" type="image" class="rounded-md"/>
				</frp-form-card-block-col>
			</frp-form-card-block-row>
		</frp-form-card-blocks>
		
		<div class="d-flex mx-n3">
			<v-spacer></v-spacer>
			
			<frp-btn-loader width="100" height="40" class="mr-4"></frp-btn-loader>
			<frp-btn-loader width="156" height="40"></frp-btn-loader>
		</div>
	</v-card>
</template>

<script>

import FrpCard from "@/components/cards/FrpCard.vue";
import FrpCustomTitleLoader from "@/components/loaders/common/FrpCustomTitleLoader.vue";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader.vue";
import FrpInputLoader from "@/components/loaders/form/FrpInputLoader.vue";
import FrpTextareaLoader from "@/components/loaders/form/FrpTextareaLoader.vue";
import BodFormCardBlock from "@/components/markup/BodFormCardBlock.vue";
import FrpFormCardBlock from "@/components/markup/FrpFormCardBlock.vue";
import FrpFormCardBlockCol from "@/components/markup/FrpFormCardBlockCol.vue";
import FrpFormCardBlockRow from "@/components/markup/FrpFormCardBlockRow.vue";
import FrpFormCardBlocks from "@/components/markup/FrpFormCardBlocks.vue";

export default {
	components: {
		FrpBtnLoader,
		FrpInputLoader,
		FrpCustomTitleLoader,
		FrpTextareaLoader,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		BodFormCardBlock,
		FrpCard
	}
};
</script>

<style lang="scss">
</style>
