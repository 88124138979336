import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import { ApiKpiUser } from "@/api/kpi/types/apiKpiUser";
import { ApiKpiDepartment } from "@/api/kpi/types/apiKpiDepartment";
import DelegacyFilter from "@/store/kpi/modules/delegacy/types/delegacyFilter";
import { ApiCreateDelegacyRequest } from "@/api/kpi/types/apiCreateDelegacyRequest";
import { DelegacyItem } from "@/types/kpi/delegacy";
import FormState from "@/store/shared/form/models/formState";

export default class DelegacyState implements IPageState {
	constructor(
		public listing: ListingModel<DelegacyItem>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public filter: DelegacyFilter,
		public route: RouteState,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDepartmentsLoading: boolean = false,
		public isAppointersLoading: boolean = false,
		public isAttorneysLoading: boolean = false,
		public isDelegacyDeleting: boolean = false,
		public appointers: ApiKpiUser[] = [],
		public attorneys: ApiKpiUser[] = [],
		public departments: ApiKpiDepartment[] = [],
		public createDelegacyRequest: ApiCreateDelegacyRequest = {} as ApiCreateDelegacyRequest
	)
	{
	}
}
