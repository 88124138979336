<template>
	<kpi-content-layout :title="$t('titles.delegacy')">
		<template #title-append>
			<frp-btn @click="isAddDelegacyDialogOpened = true" color="blue" dark elevation="0">
				{{ $t("buttons.addDelegacy") }}
			</frp-btn>
		</template>
		
		<v-card class="kpi-card px-6 py-9"
				style="border-bottom-left-radius: 0; border-bottom-right-radius: 0">
			<div class="d-flex align-center" style="gap: 16px">
				<bod-autocomplete item-text="name"
								  item-value="id"
								  class="kpi-field"
								  @firstclick="fetchDepartments"
								  hide-details
								  :readonly="isItemsLoading"
								  :loading="isDepartmentsLoading"
								  style="width: 286px"
								  :items="sortedDepartments"
								  v-model="internalFilterValues.departmentId"
								  color="blue"
								  :placeholder="$t('fields.kpiDepartment.placeholder')">
				</bod-autocomplete>
				
				<bod-autocomplete item-text="fullName"
								  item-value="id"
								  class="kpi-field"
								  @firstclick="fetchAppointers"
								  hide-details
								  :readonly="isItemsLoading"
								  :loading="isAppointersLoading"
								  style="width: 286px"
								  :items="appointers"
								  v-model="internalFilterValues.appointerId"
								  color="blue"
								  :placeholder="$t('fields.kpiFilterDelegacyAppointer.placeholder')">
				</bod-autocomplete>
				
				<bod-autocomplete item-text="text"
								  item-value="value"
								  class="kpi-field"
								  hide-details
								  style="width: 220px"
								  :readonly="isItemsLoading"
								  :items="statusItems"
								  v-model="filterStatus"
								  color="blue"
								  :placeholder="$t('fields.kpiStatus.placeholder')">
				</bod-autocomplete>
				<v-spacer></v-spacer>
				<frp-btn @click="resetFilter"
						 :disabled="isFilterEmpty"
						 color="primary"
						 outlined
						 elevation="0">
					{{ $t("buttons.reset") }}
				</frp-btn>
				
				<frp-btn @click="applyFilter"
						 :disabled="!isFilterChanged"
						 no-margin
						 color="blue"
						 dark
						 elevation="0">
					{{ $t("buttons.accept") }}
				</frp-btn>
			</div>
		</v-card>
		
		<v-row>
			<v-col class="pb-0" style="border-top: 1px solid var(--v-grey-lighten4)">
				<v-data-table :headers="headers"
							  :loading-text="$t('tables.loading')"
							  :loading="isItemsLoading"
							  hide-default-footer
							  item-key="id"
							  :items="formattedItems"
							  :items-per-page="-1"
							  :mobile-breakpoint="mobileBreakpoint"
							  :options="options"
							  class="delegacy-table clickable-rows d-flex flex-column kpi-shadow mt-4">
					<template #item.action="{ item }">
						<frp-btn v-if="!item.isDeleted" @click="handleOpenDeleteDelegacyDialog(item)" icon color="primary">
							<frp-icon class="mx-auto" src="ico_kpi-delete" :color="colors.black.lighten1"></frp-icon>
						</frp-btn>
					</template>
				</v-data-table>
				
				<frp-bottom-space height="20"></frp-bottom-space>
			</v-col>
		</v-row>
		
		<frp-dialog max-width="498" v-model="isAddDelegacyDialogOpened" :title="$t('dialogs.addDelegacy.title')">
			<template #content="{ onIntersect }">
				<v-form :readonly="isFormSaving || isFormReadonly"
						:disabled="isFormDisabled"
						:ref="refs.form"
						v-model="formValid">
					<bod-autocomplete item-text="fullName"
									  item-value="id"
									  class="kpi-field"
									  @firstclick="fetchAppointers"
									  :loading="isAppointersLoading"
									  required
									  :items="appointers"
									  v-model="createDelegacyRequest.appointerStaffId"
									  color="blue"
									  :label="$t('fields.kpiDelegacyAppointer.label')"
									  :placeholder="$t('fields.kpiDelegacyAppointer.placeholder')">
					</bod-autocomplete>
					
					<bod-autocomplete item-text="fullName"
									  item-value="id"
									  class="kpi-field"
									  :readonly="!createDelegacyRequest.appointerStaffId"
									  :loading="isAttorneysLoading"
									  required
									  :items="attorneys"
									  v-model="createDelegacyRequest.attorneyStaffId"
									  color="blue"
									  :label="$t('fields.kpiDelegacyAttorney.label')"
									  :placeholder="$t('fields.kpiDelegacyAttorney.placeholder')">
					</bod-autocomplete>
				</v-form>
			</template>
			
			<template #footer>
				<frp-btn outlined
						 @click="handleCloseAddDelegacyDialog"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn @click="addDelegacy"
						 dark
						 :loading="isFormSaving"
						 :disabled="!stateContainsUnsavedChanges || !isFormValid"
						 color="blue">
					{{ $t("buttons.add") }}
				</frp-btn>
			</template>
		</frp-dialog>
		
		<frp-dialog v-model="isDeleteDelegacyDialogOpened" :title="$t('dialogs.deleteDelegacy.title')">
			<template #content="{ onIntersect }">
				<span v-if="itemToDelete">
					{{ $t("dialogs.deleteDelegacy.text", { user: itemToDelete.attorneyWithInitials }) }}
				</span>
			</template>
			
			<template #footer>
				<frp-btn outlined
						 @click="isDeleteDelegacyDialogOpened = false"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn @click="handleDeleteDelegacy"
						 :loading="isDelegacyDeleting"
						 color="secondary">
					{{ $t("buttons.remove") }}
				</frp-btn>
			</template>
		</frp-dialog>
		
		<template #footer>
			<bod-footer></bod-footer>
		</template>
	</kpi-content-layout>
</template>

<script>
import FrpPagination from "Components/common/FrpPagination";
import FrpDialog from "Components/dialogs/FrpDialog";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import FrpIcon from "Components/icon/FrpIcon";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import KpiContentLayout from "Components/layouts/KpiContentLayout";
import { assign, isEqual } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import formMixin from "Mixins/formMixin";
import { i18n } from "Plugins/index";
import { RouteNames } from "Router/kpi/routes";
import { namespace } from "Store/kpi/modules/delegacy";
import { actionTypes, getterTypes, mutationTypes } from "Store/kpi/modules/delegacy/types";
import DelegacyFilter from "Store/kpi/modules/delegacy/types/delegacyFilter";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import FrpAlert from "Components/alerts/FrpAlert";
import FrpAlerts from "Components/alerts/FrpAlerts";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpTextField from "Components/fields/FrpTextField";
import BodFooter from "Components/layouts/BodFooter";
import BodMain from "Components/layouts/BodMain";
import { DelegacyStatusEnum } from "Types/kpi/delegacyStatusEnum";
import { uniqBy } from "lodash";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage, authorizationMixin, formMixin],
	metaInfo() {
		return {
			title: i18n.t("metaTitles.delegacy")
		};
	},
	data() {
		return {
			RouteNames,
			namespace,
			uniqBy,
			DelegacyStatusEnum,
			headers: [
				{
					text: this.$t("tables.date"),
					value: "date",
					width: "15%",
					sortable: false
				},
				{
					text: this.$t("tables.delegacyDepartment"),
					value: "department",
					width: "26%",
					sortable: false
				},
				{
					text: this.$t("tables.appointer"),
					value: "appointer",
					width: "26%",
					sortable: false
				},
				{
					text: this.$t("tables.attorney"),
					value: "attorney",
					width: "26%",
					sortable: false
				},
				{
					text: "",
					value: "action",
					width: "6%",
					sortable: false
				}
			],
			internalFilterValues: {
				departmentId: "",
				appointerId: "",
				status: DelegacyStatusEnum.ACTIVE
			},
			isAddDelegacyDialogOpened: false,
			isDeleteDelegacyDialogOpened: false,
			itemToDelete: null
		};
	},
	computed: {
		...mapState({
			initialized: state => state.isInitialized,
			isAppointersLoading: state => state.isAppointersLoading,
			isAttorneysLoading: state => state.isAttorneysLoading,
			isDepartmentsLoading: state => state.isDepartmentsLoading,
			isDelegacyDeleting: state => state.isDelegacyDeleting,
			appointers: state => state.appointers,
			attorneys: state => state.attorneys,
			departments: state => state.departments,
			createDelegacyRequest: state => state.createDelegacyRequest
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty
		}),
		filterStatus: {
			get() {
				return this.internalFilterValues.status;
			},
			set(value)
			{
				this.internalFilterValues.status = value || DelegacyStatusEnum.ALL;
			}
		},
		filter() {
			return {
				departmentId: this.internalFilterValues.departmentId,
				appointerId: this.internalFilterValues.appointerId,
				status: this.internalFilterValues.status
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v === null ? [] : v])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			return isEqual(new DelegacyFilter(), this.filterValues);
		},
		sortedDepartments() {
			return this.departments.sort((a, b) => a.name.localeCompare(b.name));
		},
		statusItems() {
			return [DelegacyStatusEnum.ACTIVE, DelegacyStatusEnum.NOT_ACTIVE].map(x => ({
				value: x,
				text: this.$t(`aliases.delegacyStatus.${x}`)
			}));
		}
	},
	methods: {
		...mapMutations({
			setFilterAppointerId: mutationTypes.SET_FILTER_APPOINTER_ID,
			setFilterDepartmentId: mutationTypes.SET_FILTER_DEPARTMENT_ID,
			setFilterStatus: mutationTypes.SET_FILTER_STATUS,
			resetFilter: mutationTypes.RESET_FILTER,
			setCreateDelegacyRequestAppointerId: mutationTypes.SET_CREATE_DELEGACY_REQUEST_APPOINTER_ID,
			setCreateDelegacyRequestAttorneyId: mutationTypes.SET_CREATE_DELEGACY_REQUEST_ATTORNEY_ID
		}),
		...mapActions({
			fetchAppointers: actionTypes.fetchAppointers,
			fetchAttorneys: actionTypes.fetchAttorneys,
			fetchDepartments: actionTypes.fetchDepartments,
			addDelegacy: actionTypes.addDelegacy,
			deleteDelegacy: actionTypes.deleteDelegacy
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		},
		applyFilter() {
			this.setFilterAppointerId(this.internalFilterValues.appointerId);
			this.setFilterDepartmentId(this.internalFilterValues.departmentId);
			this.setFilterStatus(this.internalFilterValues.status);
		},
		handleCloseAddDelegacyDialog() {
			this.isAddDelegacyDialogOpened = false;
			
			this.setCreateDelegacyRequestAppointerId("");
			this.setCreateDelegacyRequestAttorneyId("");
			this.$refs[this.refs.form].resetValidation();
		},
		handleOpenDeleteDelegacyDialog(item) {
			this.isDeleteDelegacyDialogOpened = true;
			this.itemToDelete = item;
		},
		handleDeleteDelegacy() {
			this.deleteDelegacy(this.itemToDelete.id);
			this.isDeleteDelegacyDialogOpened = false;
		}
	},
	watch: {
		"createDelegacyRequest.appointerStaffId"(value) {
			this.setCreateDelegacyRequestAttorneyId("");
			this.$refs[this.refs.form].resetValidation();
			
			if(value)
				this.fetchAttorneys();
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpDialog,
		FrpBottomSpace,
		KpiContentLayout,
		BodAutocomplete,
		FrpPagination,
		FrpIcon,
		FrpBtn,
		FrpCheckbox,
		FrpTextField,
		FrpAutocomplete,
		BodMain,
		FrpAlerts,
		FrpAlert,
		BodFooter
	}
};
</script>
