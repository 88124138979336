<template>
	<v-col class="pa-0" :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :offset="offset">
		<p v-if="title || $slots.title"
		   class="bod-text-h5 primary--text text--darken-1 mb-4 d-flex justify-space-between align-start font-weight-regular flex-wrap">
			<slot name="title">
				{{ title }}
			</slot>

			<v-spacer></v-spacer>

			<slot name="side-content"></slot>
		</p>
		<slot></slot>
	</v-col>
</template>

<script>
export default {
	props: {
		title: {
			type: String
		},
		xs: {
			type: Boolean | String | Number,
			default: false
		},
		sm: {
			type: Boolean | String | Number,
			default: false
		},
		md: {
			type: Boolean | String | Number,
			default: false
		},
		lg: {
			type: Boolean | String | Number,
			default: false
		},
		xl: {
			type: Boolean | String | Number,
			default: false
		},
		offset: {
			type: String | Number,
			default: undefined
		}
	}
};
</script>

<style scoped>

</style>
