export default class RouteState {
	isPushing: Boolean;
	isProcessing: Boolean;

	constructor(isPushing: Boolean,
		isProcessing: Boolean)
	{
		this.isPushing = isPushing;
		this.isProcessing = isProcessing;
	}
}
