import { IsNumber } from "class-validator";

export default class KpiCriteriasRouteQuery {
	@IsNumber()
	year: number;
	
	constructor(
		year: number
	)
	{
		this.year = year;
	}
}
