import { ApiCriteria } from "@/api/kpi/types/apiCriteria";

export interface Criteria {
	id: string;
	index: number;
	title: string;
	sort: number;
}

export class CriteriaHelper {
	static map(source: ApiCriteria, index: number): Criteria {
		return {
			...source,
			index
		};
	}
	
	static getEmpty(): Criteria {
		return {
			id: "",
			index: 0,
			title: "",
			sort: 100
		};
	}
}