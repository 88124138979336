import KpisState from "@/store/kpi/modules/kpis/types/kpisState";
import { ApiDownloadRegistryParameters } from "@/api/kpi/types/apiDownloadRegistryParameters";

export default class KpisMapper {
	static mapToApiDownloadRegistry(source: KpisState): ApiDownloadRegistryParameters {
		return {
			staffIds: source.filter.staffIds,
			departmentIds: source.filter.departmentId ? [source.filter.departmentId] : undefined
		};
	}
}
