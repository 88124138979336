import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import TroubledBorrowersRouteQuery from "@/store/loan/modules/troubledBorrowers/types/troubledBorrowersRouteQuery";
import TroubledBorrowersState from "@/store/loan/modules/troubledBorrowers/types/troubledBorrowersState";
import KpiCriteriasRouteQuery from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/kpiCriteriasRouteQuery";
import KpiCriteriasInfoState from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/kpiCriteriasInfoState";
import { getYear } from "date-fns";

export default class KpiCriteriasRouteQueryService {
	defaultRouteQuery: KpiCriteriasRouteQuery;
	
	constructor(defaultRouteQuery: KpiCriteriasRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}
	
	resolveRouteQueryDictionary(state: KpiCriteriasInfoState) {
		let query = new KpiCriteriasRouteQuery(
			state.filter.year
		);
		
		return difference(this.defaultRouteQuery, query);
	}
	
	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(KpiCriteriasRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				year: query.year || getYear(new Date())
			}, { enableImplicitConversion: true });
			
			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
