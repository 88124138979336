import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/kpi/modules/kpi/modules/mainInfo/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import AbortService from "@/services/abortService";
import { KpiController } from "@/api/kpi";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import KpiMainInfoState from "@/store/kpi/modules/kpi/modules/mainInfo/types/KpiMainInfoState";
import { chain, cloneDeep, differenceWith, isEqual, uniqBy, uniqWith } from "lodash";
import { KpiMainInfoMapper } from "@/store/kpi/modules/kpi/modules/mainInfo/mapper";
import storeManager from "@/store/manager";
import KpiState from "@/store/kpi/modules/kpi/types/kpiState";
import { KpiMainInfoModeTypeEnum } from "@/store/kpi/modules/kpi/modules/mainInfo/types/KpiMainInfoModeTypeEnum";
import { KpiMainInfoHelper } from "@/store/kpi/modules/kpi/modules/mainInfo/types/kpiMainInfo";
import { Criteria, CriteriaHelper } from "@/store/kpi/modules/kpi/types/criteria";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import router from "@/router/kpi";
import { RouteNames } from "@/router/kpi/routes";
import { ApiAddKpiRequestHelper } from "@/api/kpi/types/apiAddKpiRequest";
import { ApiUpdateKpiRequestMapper } from "@/api/kpi/types/apiUpdateKpiRequest";
import { actionTypes as rootActionTypes } from "@/store/kpi/types";
import { KpiMatrixItem, KpiMatrixMapper } from "@/types/kpi/kpiMatrixItem";
import { parseIds } from "@/store/kpi/helpers/staffDepartmentPosition";
import { AddKpiStaff } from "@/types/kpi/addKpiStaff";
import SubscribersManager from "@/store/manager/subscribersManager";
import { KpiCriteriasModeTypeEnum } from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/KpiCriteriasModeTypeEnum";

const abortService = new AbortService();
const kpiController = new KpiController(abortService);
const mapper = new KpiMainInfoMapper();

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["editableItem", "matrix"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new KpiMainInfoState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

let subscribersManager: SubscribersManager<KpiMainInfoState>;
let unsubscribeCallback = () => {};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<KpiMainInfoState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<KpiMainInfoState>(rootState, namespace);
	
	switch (mutation.type) {
		case resolveMutation(namespace, mutationTypes.SET_MODE):
			if(mutation.payload === KpiCriteriasModeTypeEnum.EDIT)
				subscribersManager.commit(resolveMutation(namespace, mutationTypes.SET_STATE_SNAPSHOT), stateSnapshotKeys.LAST_SAVED);
			
			break;
		default:
			break;
	}
};

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<KpiMainInfoState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.formattedEditableItemPeriods]: state => {
		return chain(state.editableItem.periods).groupBy(x => x.year).map((value, key) => {
			// @ts-ignore
			return { year: key, quarters: value.map(x => x.quarter).sort((a, b) => a - b) };
		}).value();
	},
	[getterTypes.isReadMode]: state => {
		return state.mode === KpiMainInfoModeTypeEnum.READ;
	},
	[getterTypes.isEditMode]: state => {
		return state.mode === KpiMainInfoModeTypeEnum.EDIT;
	}
};

const actions = <ActionTree<KpiMainInfoState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state, rootState }, { id }) {
		await dispatch(actionTypes.initializeBase);
		
		commit(mutationTypes.SET_ID, id);
		
		if(state.id) {
			const { kpi } = resolveNestedState<KpiState>(rootState, storeManager.kpi.kpi.namespace);
			
			const kpiMainInfo = mapper.mapToKpiMainInfo(kpi);
			
			commit(mutationTypes.SET_EDITABLE_ITEM, kpiMainInfo);
		} else {
			const emptyEditableItem = CriteriaHelper.getEmpty();
			
			commit(mutationTypes.SET_EDITABLE_ITEM_CRITERIAS, [emptyEditableItem]);
			commit(mutationTypes.SET_MODE, KpiMainInfoModeTypeEnum.EDIT);
		}
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.addEditableItemCriteria]({ commit, state }) {
		const rawNewEmptyItem = CriteriaHelper.getEmpty();
		
		const mappedEditableItems = state.editableItem.criterias.map((x: Criteria, index: number) => ({
			...x, index, sort: (index + 1) * 100
		}));
		
		const newEmptyItemIndex = mappedEditableItems.length;
		const newEmptyItem = { ...rawNewEmptyItem, index: newEmptyItemIndex, sort: (newEmptyItemIndex + 1) * 100 };
		
		const sortedCriterias = mappedEditableItems.sort((a, b) => a.sort - b.sort);
		
		commit(mutationTypes.SET_EDITABLE_ITEM_CRITERIAS, [...sortedCriterias, newEmptyItem]);
	},
	async [actionTypes.deleteEditableItemCriteria]({ commit, state }, { index, id }) {
		// удаляем по индексу айтем, потом мы проходим по всем editableItems и обновляем у всех в модели индекс, исходя из текущего индекса.
		let editableCriteriaItems = cloneDeep(state.editableItem.criterias);
		editableCriteriaItems.splice(index, 1);
		
		const mappedEditableCriteriaItems = editableCriteriaItems.map((x: Criteria, index: number) => ({ ...x, index }));
		
		commit(mutationTypes.SET_EDITABLE_ITEM_CRITERIAS, mappedEditableCriteriaItems);
	},
	async [actionTypes.addEditableItemPeriods]({ commit, state }, { year, quarters }) {
		const periodsWithoutSelectedYear = state.editableItem.periods.filter(x => x.year !== +year);
		
		const formattedQuartersWithSelectedYear = quarters.map((x: number | null) => ({ year, quarter: x }));
		
		commit(mutationTypes.SET_EDITABLE_ITEM_PERIODS, [...periodsWithoutSelectedYear, ...formattedQuartersWithSelectedYear]);
	},
	async [actionTypes.deleteEditableItemFullPeriod]({ commit, state, getters }, { year }) {
		if(getters.isReadMode)
			return;
		
		commit(mutationTypes.SET_EDITABLE_ITEM_PERIODS, state.editableItem.periods.filter(x => x.year !== +year));
		commit(mutationTypes.REMOVE_EDITABLE_ITEM_KPI_STAFFS_ITEM_BY_YEAR, { year });
	},
	async [actionTypes.deleteEditableItemPeriodsByQuarter]({ commit, state, getters }, { year, quarter }) {
		if(getters.isReadMode)
			return;
		
		commit(mutationTypes.DELETE_EDITABLE_ITEM_PERIODS_ITEM, { year, quarter });
		commit(mutationTypes.REMOVE_EDITABLE_ITEM_KPI_STAFFS_ITEM_BY_PERIOD, { year, quarter });
	},
	async [actionTypes.setRegularIndicator]({ commit, rootState, state, getters }, value) {
		if(!value) {
			commit(mutationTypes.SET_EDITABLE_ITEM_IS_REGULAR, value);
		} else {
			// если регулярный показатель true, то мы проставляем все существующие кварталы по выбранным годам
			const { periods } = resolveNestedState<KpiState>(rootState, storeManager.kpi.kpi.namespace);
			
			// На каждый период добавляются элементы KpiStaff для матрицы
			const currentPeriods = uniqWith(state.editableItem.kpiStaffs.map(x => x.period), isEqual);
			const addedPeriods = differenceWith(periods, currentPeriods, isEqual);
			const uniqStaffs = uniqBy(state.editableItem.kpiStaffs, "ids");
			
			addedPeriods.forEach((period) => {
				uniqStaffs.forEach(staff => {
					commit(mutationTypes.ADD_EDITABLE_ITEM_KPI_STAFFS_ITEM, {
						...staff,
						period,
						isChecked: true
					});
				});
			});
			
			commit(mutationTypes.SET_EDITABLE_ITEM_PERIODS, periods);
			commit(mutationTypes.SET_EDITABLE_ITEM_IS_REGULAR, value);
		}
	},
	async [actionTypes.save]({ dispatch, commit, state }, { id }) {
		commit(mutationTypes.SET_IS_KPI_MAIN_INFO_FORM_SAVING, true);
		
		try {
			dispatch(actionTypes.remapSortCriteriaItems);
			
			const kpiStaffs: AddKpiStaff[] = [];
			
			state.editableItem.kpiStaffs.forEach(x => {
				const item = state.matrix.find(y => y.ids === x.ids && isEqual(x.period, y.period));
				
				if(item)
					kpiStaffs.push({ ...x, isChecked: item.isChecked });
			});
			
			if(id) {
				
				const payload = ApiUpdateKpiRequestMapper.map({ ...state.editableItem, kpiStaffs });
				
				await kpiController.updateKpi(id, payload);
				
				alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
				
				// нужно перезапускать страничку без обновления, чтобы левая и правая части синхронизировались
				commit(mutationTypes.SET_KPI_MAIN_INFO_UPDATED, payload);
			} else {
				const payload = ApiAddKpiRequestHelper.map({ ...state.editableItem, kpiStaffs });
				
				id = await kpiController.createKpi(payload);
				
				alertService.addInfo(AlertKeys.SUCCESS_CREATED_INFO);
				await router.push({ name: RouteNames.KPI, params: { id } });
			}
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_KPI_MAIN_INFO_FORM_SAVING, false);
		}
	},
	[actionTypes.remapSortCriteriaItems]({ commit, state, dispatch }) {
		const reformattedSortCriteriaItems = state.editableItem.criterias.map((x: Criteria, index: number) => ({
			...x, index, sort: (index + 1) * 100
		}));
		commit(mutationTypes.SET_EDITABLE_ITEM_CRITERIAS, reformattedSortCriteriaItems);
	},
	async [actionTypes.deleteKpi]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_KPI_DELETING, true);
		
		try {
			await kpiController.deleteKpi(state.id);
			
			alertService.addInfo(AlertKeys.KPI_SUCCESS_DELETED);
			await router.push({ name: RouteNames.KPIS });
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_KPI_DELETING, false);
		}
	},
	async [actionTypes.fetchMatrix]({ commit, state, dispatch }) {
		if(!state.editableItem.periods.length || !state.editableItem.kpiStaffs.length) {
			commit(mutationTypes.SET_MATRIX, []);
			return;
		}
		
		commit(mutationTypes.SET_IS_MATRIX_LOADING, true);
		
		try {
			const { kpiMatrixItems } = await kpiController.getMatrix({
				periods: state.editableItem.periods,
				staffDepartmentPositionIds: uniqBy(state.editableItem.kpiStaffs, "ids").map(x => parseIds(x.ids))
			});
			
			const mapped = kpiMatrixItems.map(x => KpiMatrixMapper.map(x));
			
			const applyValues = (valuesArray: (KpiMatrixItem | AddKpiStaff)[]) => {
				return mapped.map(x => {
					const item = valuesArray.find(y => y.ids === x.ids && isEqual(y.period, x.period));
					
					return { ...x, isChecked: item?.isChecked ?? true };
				});
			};
			
			if(!state.matrix.length) {
				const withValues = applyValues(state.editableItem.kpiStaffs);
				
				commit(mutationTypes.SET_MATRIX, withValues);
			} else {
				const withValues = applyValues(state.matrix);
				
				commit(mutationTypes.SET_MATRIX, withValues);
			}
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_MATRIX_LOADING, false);
		}
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<KpiMainInfoState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[mutationTypes.SET_IS_KPI_MAIN_INFO_FORM_SAVING](state, value) {
		state.isKpiMainInfoFormSaving = value;
	},
	[mutationTypes.SET_IS_KPI_DELETING](state, value) {
		state.isKpiDeleting = value;
	},
	[mutationTypes.SET_IS_KPI_MAIN_INFO_FORM_VALID](state, value) {
		state.isKpiMainInfoFormValid = value;
	},
	[mutationTypes.RESET_EDITABLE_ITEM](state) {
		state.editableItem = KpiMainInfoHelper.getEmpty();
	},
	[mutationTypes.SET_EDITABLE_ITEM](state, value) {
		state.editableItem = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_ITEM_TITLE](state, value) {
		state.editableItem.title = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_METHODOLOGY](state, value) {
		state.editableItem.methodology = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_IS_REGULAR](state, value) {
		state.editableItem.isRegular = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_CRITERIAS](state, value) {
		state.editableItem.criterias = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_ITEM_STAFF_IDS](state, value) {
		state.editableItem.kpiStaffs = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_ITEM_PERIODS](state, value) {
		state.editableItem.periods = cloneDeep(value);
	},
	[mutationTypes.DELETE_EDITABLE_ITEM_PERIODS_ITEM](state, { year, quarter }) {
		state.editableItem.periods.splice(state.editableItem.periods.findIndex(x => x.year === +year && x.quarter === quarter), 1);
	},
	[mutationTypes.ADD_EDITABLE_ITEM_KPI_STAFFS_ITEM](state, value) {
		state.editableItem.kpiStaffs.push(cloneDeep(value));
	},
	[mutationTypes.REMOVE_EDITABLE_ITEM_KPI_STAFFS_ITEM_BY_YEAR](state, { year }) {
		state.editableItem.kpiStaffs = state.editableItem.kpiStaffs.filter(x => x.period.year !== +year);
	},
	[mutationTypes.REMOVE_EDITABLE_ITEM_KPI_STAFFS_ITEM_BY_PERIOD](state, { year, quarter }) {
		state.editableItem.kpiStaffs = state.editableItem.kpiStaffs.filter(x => x.period.year !== +year || x.period.quarter !== quarter);
	},
	[mutationTypes.SET_MATRIX_ITEM_IS_CHECKED](state, { ids, year, quarter, value }) {
		state.matrix.find(x => x.period.year === +year && x.period.quarter === quarter && x.ids === ids)!.isChecked = value;
	},
	[mutationTypes.SET_KPI_MAIN_INFO_UPDATED]() {
	},
	[mutationTypes.SET_IS_MATRIX_LOADING](state, value) {
		state.isMatrixLoading = value;
	},
	[mutationTypes.SET_MATRIX](state, value) {
		state.matrix = cloneDeep(value);
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const kpiMainInfoModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default kpiMainInfoModule;
