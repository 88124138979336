import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { isEqual } from "lodash";
import DelegacyRouteQuery from "@/store/kpi/modules/delegacy/types/delegacyRouteQuery";
import DelegacyState from "@/store/kpi/modules/delegacy/types/delegacyState";
import { DelegacyStatusEnum } from "@/types/kpi/delegacyStatusEnum";

export default class DelegacyRouteService {
	defaultRouteQuery: DelegacyRouteQuery;

	constructor(defaultRouteQuery: DelegacyRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: DelegacyState) {
		let query = new DelegacyRouteQuery(
			state.filter.departmentId,
			state.filter.appointerId,
			state.filter.status
		);

		const res = {};
		Object.keys(this.defaultRouteQuery).forEach(key => {
			//@ts-ignore
			if(!isEqual(this.defaultRouteQuery[key], query[key]))
				//@ts-ignore
				res[key] = query[key];
		});

		return res;
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(DelegacyRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				status: query.status || DelegacyStatusEnum.ACTIVE
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
