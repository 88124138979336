import { KpiQuarterEnum } from "@/types/kpi/KpiQuarterEnum";

export interface KpiDownloadScoreCardsRequest {
	year: number | null;
	period: KpiQuarterEnum | null;
}

export class KpiDownloadScoreCardsRequestService {	
	static getEmpty(): KpiDownloadScoreCardsRequest {
		return {
			year: null,
			period: null
		};
	}
}
