<template>
	<div>
		<v-skeleton-loader v-if="label" height="12" max-width="100" type="image" class="mb-2 rounded-lg"/>
		<v-skeleton-loader :height="textareaHeight" type="image" class="mb-6 rounded-md"/>
	</div>
</template>

<script>
export default {
	props: {
		label: {
			type: Boolean,
			default: true
		},
		textareaHeight: {
			type: [String, Number],
			default: 72
		}
	}
};
</script>

<style scoped>

</style>
