<template>
	<v-container fluid>
		<v-row class="align-center justify-space-between">
			<v-col class="pt-0">
				<span style="white-space: nowrap" class="text-h6 blue--text text--darken-4">
					<slot name="title"></slot>
				</span>
			</v-col>
			<v-col style="width: fit-content" class="flex-grow-0 d-flex pt-0">
				<slot name="actions"></slot>
			</v-col>
		</v-row>

		<v-row class="mt-0 fill-height">
			<v-col>
				<slot name="content"></slot>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {};
</script>

<style scoped>

</style>
