import { stringifyQuery } from "@/utils/query";
import FrpInternalServerError from "@/views/errors/FrpInternalServerError.vue";
import FrpAccessDeniedError from "@/views/errors/FrpAccessDeniedError.vue";
import FrpPageNotFound from "@/views/errors/FrpPageNotFound.vue";
import FrpSilentRenew from "@/views/FrpSilentRenew.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import KpiLayout from "@/views/layouts/KpiLayout.vue";
import KpiKpis from "@/views/kpi/kpis/KpiKpis.vue";
import KpiReporting from "@/views/kpi/reporting/KpiReporting.vue";
import KpiDelegacy from "@/views/kpi/delegacy/KpiDelegacy.vue";
import FrpEmptyLayout from "@/views/layouts/FrpEmptyLayout.vue";
import FrpCallback from "@/views/FrpCallback.vue";
import { RouteNames } from "@/router/kpi/routes";
import { configureMiddleware } from "@/router/kpi/middleware";
import FrpRouterView from "@/components/common/FrpRouterView.vue";
import KpiKpi from "@/views/kpi/kpi/KpiKpi.vue";
import HrSupport from "@/views/kpi/support/HrSupport.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/callback.html",
		component: FrpCallback,
		meta: { public: true },
		name: RouteNames.CALLBACK
	},
	{
		path: "/silent-renew.html",
		component: FrpSilentRenew,
		meta: { public: true },
		name: RouteNames.SILENT_RENEW
	},
	{
		path: "/",
		component: KpiLayout,
		name: RouteNames.ROOT,
		children: [
			{
				path: "kpi",
				component: FrpRouterView,
				children: [
					{
						path: ":id",
						component: KpiKpi,
						name: RouteNames.KPI
					},
					{
						path: "",
						component: KpiKpi,
						name: RouteNames.KPI_CREATE
					},
				]
			},
			{
				path: "/reporting/:year/:quarter?",
				component: KpiReporting,
				name: RouteNames.REPORTING
			},
			{
				path: "/registry/:year",
				component: KpiKpis,
				name: RouteNames.KPIS
			},
			{
				path: "/delegacy",
				component: KpiDelegacy,
				name: RouteNames.DELEGACY
			},
			{
				path: "/support",
				component: HrSupport,
				name: RouteNames.SUPPORT
			},
		]
	},
	{
		path: "/",
		component: FrpEmptyLayout,
		meta: { public: true },
		children: [
			{
				path: "internal-server-error",
				component: FrpInternalServerError,
				name: RouteNames.ERROR_INTERNAL_SERVER_ERROR
			},
			{
				path: "access-denied",
				component: FrpAccessDeniedError,
				name: RouteNames.ERROR_ACCESS_DENIED
			},
			{
				path: "*",
				component: FrpPageNotFound,
				name: RouteNames.ERROR_PAGE_NOT_FOUND
			}
		]
	}
];

const router = new VueRouter({
	routes,
	mode: "history",
	stringifyQuery: query => {
		// @ts-ignore
		let result = stringifyQuery(query);
		return result ? ("?" + result) : "";
	}
});

configureMiddleware(router);

export default router;
