import { IsArray, IsEnum, IsNumber, IsString } from "class-validator";
import { KpiStatusEnum } from "@/types/kpi/kpiStatusEnum";

export default class KpisFilter {
	@IsNumber()
	year: number;

	@IsArray()
	staffIds: string[];

	@IsString()
	departmentId: string;

	@IsString()
	kpi: string;

	@IsEnum(() => KpiStatusEnum)
	status: KpiStatusEnum;

	constructor(
		year: number = 0,
		staffIds: string[] = [],
		departmentId: string = "",
		kpi: string = "",
		status: KpiStatusEnum = KpiStatusEnum.ALL
	)
	{
		this.year = year;
		this.staffIds = staffIds;
		this.departmentId = departmentId;
		this.kpi = kpi;
		this.status = status;
	}
}
