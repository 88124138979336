import { KpiPeriod } from "@/types/kpi/KpiPeriod";

export interface ApiKpiPeriod {
	year: number;
	quarter?: number | null;
}

export class ApiKpiPeriodMapper {
	static map(source: KpiPeriod): ApiKpiPeriod {
		return {
			year: source.year,
			quarter: source.quarter || undefined
		};
	}
}