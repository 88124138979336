import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { parseArrayParameter } from "@/utils/query";
import ReportingRouteQuery from "@/store/kpi/modules/reporting/types/reportingRouteQuery";
import ReportingState from "@/store/kpi/modules/reporting/types/reportingState";
import { getYear } from "date-fns";
import ReportingRouteParams from "@/store/kpi/modules/reporting/types/reportingRouteParams";
import { KpiQuarterEnum } from "@/types/kpi/KpiQuarterEnum";

export default class ReportingRouteService {
	defaultRouteQuery: ReportingRouteQuery;
	defaultRouteParams: ReportingRouteParams;

	constructor(defaultRouteQuery: ReportingRouteQuery, defaultRouteParams: ReportingRouteParams) {
		this.defaultRouteQuery = defaultRouteQuery;
		this.defaultRouteParams = defaultRouteParams;
	}

	resolveRouteQueryDictionary(state: ReportingState) {
		let query = new ReportingRouteQuery(
			state.filter.kpiId,
			state.filter.staffIds
		);

		return difference(this.defaultRouteQuery, query);
	}

	resolveRouteParamsDictionary(state: ReportingState): Dictionary<string> {
		let params = new ReportingRouteParams(
			state.filter.year,
			state.filter.quarter === KpiQuarterEnum.YEAR ? null : state.filter.quarter
		);
		return difference(this.defaultRouteParams, params);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(ReportingRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				staffIds: parseArrayParameter(query.staffIds)
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}

	async resolveRouteParams(params: Dictionary<any>) {
		try {
			let result = plainToInstance(ReportingRouteParams, {
				...this.defaultRouteParams,
				...params,
				year: +params.year,
				quarter: params.quarter || KpiQuarterEnum.YEAR
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteParams;
		}
	}
}
