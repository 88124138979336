import { ApiKpiUser } from "@/api/kpi/types/apiKpiUser";
import IPageState from "@/store/shared/base/types/pageState";

export default class UserState implements IPageState {
	constructor(
		public user: ApiKpiUser = {} as ApiKpiUser,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isUserLoading: boolean = false
	)
	{
	}
}
