<template>
	<v-col :cols="cols"
		   :sm="sm"
		   :md="md"
		   :lg="lg"
		   :xl="xl"
		   :offset="offset"
		   :offset-sm="offsetSm"
		   :offset-md="offsetMd"
		   :offset-lg="offsetLg"
		   :offset-xl="offsetXl">
		<slot></slot>
	</v-col>
</template>

<script>
export default {
	props: {
		cols: {
			type: Boolean | String | Number,
			default: false
		},
		sm: {
			type: Boolean | String | Number,
			default: false
		},
		md: {
			type: Boolean | String | Number,
			default: false
		},
		lg: {
			type: Boolean | String | Number,
			default: false
		},
		xl: {
			type: Boolean | String | Number,
			default: false
		},
		offset: {
			type: String | Number,
			default: undefined
		},
		offsetSm: {
			type: String | Number,
			default: undefined
		},
		offsetMd: {
			type: String | Number,
			default: undefined
		},
		offsetLg: {
			type: String | Number,
			default: undefined
		},
		offsetXl: {
			type: String | Number,
			default: undefined
		}
	}
};
</script>

<style scoped>

</style>
