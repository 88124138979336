import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { parseArrayParameter } from "@/utils/query";
import { isEqual } from "lodash";
import KpisState from "../types/kpisState";
import KpisRouteQuery from "../types/kpisRouteQuery";
import { KpiStatusEnum } from "@/types/kpi/kpiStatusEnum";
import KpisRouteParams from "@/store/kpi/modules/kpis/types/kpisRouteParams";
import { difference } from "@/utils/difference";
import { getYear } from "date-fns";

export default class KpisRouteService {
	defaultRouteQuery: KpisRouteQuery;
	defaultRouteParams: KpisRouteParams;

	constructor(defaultRouteQuery: KpisRouteQuery, defaultRouteParams: KpisRouteParams) {
		this.defaultRouteQuery = defaultRouteQuery;
		this.defaultRouteParams = defaultRouteParams;
	}

	resolveRouteQueryDictionary(state: KpisState) {
		let query = new KpisRouteQuery(
			state.filter.staffIds,
			state.filter.departmentId,
			state.filter.kpi,
			state.filter.status
		);

		const res = {};
		Object.keys(this.defaultRouteQuery).forEach(key => {
			//@ts-ignore
			if(!isEqual(this.defaultRouteQuery[key], query[key]))
				//@ts-ignore
				res[key] = query[key];
		});

		return res;
	}

	resolveRouteParamsDictionary(state: KpisState) {
		let params = new KpisRouteParams(
			state.filter.year
		);
		
		return difference(this.defaultRouteParams, params);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(KpisRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				staffIds: parseArrayParameter(query.staffIds),
				status: query.status || KpiStatusEnum.ACTIVE
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}

	async resolveRouteParams(params: Dictionary<any>) {
		try {
			let result = plainToInstance(KpisRouteParams, {
				...this.defaultRouteParams,
				...params,
				year: params.year ? +params.year : getYear(new Date())
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteParams;
		}
	}
}
