import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/kpi/modules/kpi/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import { KpiController } from "@/api/kpi";
import KpiState from "@/store/kpi/modules/kpi/types/kpiState";
import router from "@/router/kpi";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import kpiMainInfoModule from "@/store/kpi/modules/kpi/modules/mainInfo";
import kpiCriteriasInfoModule from "@/store/kpi/modules/kpi/modules/criteriasInfo";
import kpiMainInfoTypes from "@/store/kpi/modules/kpi/modules/mainInfo/types";
import kpiCriteriasInfoTypes from "@/store/kpi/modules/kpi/modules/criteriasInfo/types";
import { formatFullName } from "@/utils/formatting";
import { KpiPeriod, KpiPeriodMapper } from "@/types/kpi/KpiPeriod";
import { uniq } from "lodash";
import storeManager from "@/store/manager";
import KpiCriteriasInfoState from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/kpiCriteriasInfoState";
import KpiMainInfoState from "@/store/kpi/modules/kpi/modules/mainInfo/types/KpiMainInfoState";
import { Permissions } from "@/constants/permissions";
import { RouteNames } from "@/router/kpi/routes";
import { actionTypes as rootActionTypes } from "@/store/kpi/types";
import { getIds } from "@/store/kpi/helpers/staffDepartmentPosition";

const abortService = new AbortService();
const kpiController = new KpiController(abortService);

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new KpiState();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<KpiState, any>>{
	[getterTypes.staffUsers]: state => {
		return state.staffUsers.map(x => {
			return {
				ids: getIds(x.id, x.department.id, x.position.id),
				text: `${formatFullName(x)} - ${x.department.name} - ${x.position.title}`
			};
		});
	},
	[getterTypes.periodYears]: state => {
		return uniq(state.periods.map(x => x.year));
	},
	[getterTypes.canEditKpi]: state => {
		return state.permissions.includes(Permissions.KPI_KPI_UPDATE);
	}
};

const actions = <ActionTree<KpiState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state, rootState }) {
		await dispatch(actionTypes.initializeBase);
		
		commit(mutationTypes.SET_ID, router.currentRoute.params.id);
		
		await dispatch(actionTypes.fetchDictionaries);
		
		if(state.id) {
			await dispatch(actionTypes.fetchPermissions);
			
			if(!state.permissions.includes(Permissions.KPI_KPI_READ))
				return await router.push({ name: RouteNames.ERROR_ACCESS_DENIED });
			
			await dispatch(actionTypes.fetch);
		}
		
		await dispatch(actionTypes.initializeSubModules);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_FETCH_KPI_LOADING, true);
		
		try {
			const kpi = await kpiController.getKpi(state.id);
			
			commit(mutationTypes.SET_KPI, kpi);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_FETCH_KPI_LOADING, false);
		}
	},
	async [actionTypes.fetchDictionaries]({ commit, dispatch }) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);
		
		try {
			const periods = await kpiController.getPeriods();
			
			commit(mutationTypes.SET_PERIODS, periods.map(x => KpiPeriodMapper.map(x)));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.fetchStaffUsers]({ commit, dispatch }, periods: KpiPeriod[]) {
		commit(mutationTypes.SET_IS_STAFF_USERS_LOADING, true);
		
		try {
			const staffUsers = await kpiController.getStaffListByPeriods(periods);
			
			commit(mutationTypes.SET_STAFF_USERS, staffUsers);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_STAFF_USERS_LOADING, false);
		}
	},
	async [actionTypes.fetchPermissions]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_PERMISSIONS_LOADING, true);
		
		try {
			const permissions = await kpiController.getKpiPermissions(state.id);
			commit(mutationTypes.SET_PERMISSIONS, permissions);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_PERMISSIONS_LOADING, false);
		}
	},
	async [actionTypes.initializeSubModules]({ dispatch, rootState, commit, state }) {
		const id = state.id;
		
		await dispatch(resolveAction(kpiMainInfoModule.namespace, kpiMainInfoTypes.actionTypes.initialize), { id }, { root: true });
		
		if(id)
			await dispatch(resolveAction(kpiCriteriasInfoModule.namespace, kpiCriteriasInfoTypes.actionTypes.initialize), { id }, { root: true });
	},
	async [actionTypes.destroy]({ rootState, dispatch }) {
		await dispatch(actionTypes.destroyBase);
		
		const { isInitialized: isKpiMainInfoInitialized } = resolveNestedState<KpiMainInfoState>(rootState, storeManager.kpi.kpi.mainInfo.namespace);
		const { isInitialized: isKpiCriteriasInfoInitialized } = resolveNestedState<KpiCriteriasInfoState>(rootState, storeManager.kpi.kpi.criteriasInfo.namespace);
		
		if(isKpiMainInfoInitialized)
			await dispatch(resolveAction(kpiMainInfoModule.namespace, kpiMainInfoTypes.actionTypes.destroyBase), {}, { root: true });
		if(isKpiCriteriasInfoInitialized)
			await dispatch(resolveAction(kpiCriteriasInfoModule.namespace, kpiCriteriasInfoTypes.actionTypes.destroyBase), {}, { root: true });
	}
};

const mutations = <MutationTree<KpiState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_IS_FETCH_KPI_LOADING](state, value) {
		state.isFetchKpiLoading = value;
	},
	[mutationTypes.SET_KPI](state, value) {
		state.kpi = value;
	},
	[mutationTypes.SET_IS_PERMISSIONS_LOADING](state, value) {
		state.isPermissionsLoading = value;
	},
	[mutationTypes.SET_PERMISSIONS](state, value) {
		state.permissions = value;
	},
	[mutationTypes.SET_IS_DICTIONARIES_LOADING](state, value) {
		state.isDictionariesLoading = value;
	},
	[mutationTypes.SET_PERIODS](state, value) {
		state.periods = value;
	},
	[mutationTypes.SET_STAFF_USERS](state, value) {
		state.staffUsers = value;
	},
	[mutationTypes.SET_IS_STAFF_USERS_LOADING](state, value) {
		state.isStaffUsersLoading = value;
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;
	
	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.kpi.kpi.mainInfo.namespace, kpiMainInfoTypes.mutationTypes.SET_KPI_MAIN_INFO_UPDATED):
			{
				dispatch(resolveAction(namespace, actionTypes.destroy));
				dispatch(resolveAction(namespace, actionTypes.initialize));
			}
			
			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const kpiModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default kpiModule;
