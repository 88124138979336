<template>
	<div class="d-flex flex-column" style="height: 100%; width: 100%">
		<v-main>
			<v-container fluid
						 class="py-1 px-4 align-start justify-start"
						 fill-height>
				<loan-card-layout>
					<template #content>
						<v-container class="fill-height align-start pa-0" fluid>
							<v-row>
								<v-col>
									<div class="d-flex align-center mb-4">
										<span class="kpi-title">{{ title }}</span>
										<v-spacer></v-spacer>
										<slot name="title-append"></slot>
									</div>
									
									<slot></slot>
								</v-col>
							</v-row>
						</v-container>
					</template>
				</loan-card-layout>
			</v-container>
		</v-main>
		
		<slot name="footer">
			<bod-footer></bod-footer>
		</slot>
	</div>
</template>

<script>
import BodFooter from "Components/layouts/BodFooter";
import BodMain from "Components/layouts/BodMain";
import LoanCardLayout from "Components/layouts/LoanCardLayout";

export default {
	components: { BodMain, BodFooter, LoanCardLayout },
	props: {
		title: String
	}
};
</script>

<style scoped lang="scss">
.kpi-title {
	font-size: 25px;
	font-family: "Open-Sans", sans-serif;
	font-weight: 600;
}
</style>
