<template>
	<kpi-content-layout :title="title">
		<template #title-append>
			<frp-btn @click="isDownloadScoreCardsDialogOpened = true"
					 :disabled="!filterValues.year"
					 v-if="canReadEvaluationReportsArchive"
					 color="blue" dark elevation="0">
				{{ $t("buttons.downloadScoreCards") }}
			</frp-btn>
		</template>
		
		<v-card class="kpi-card px-6 py-9"
				style="border-bottom-left-radius: 0; border-bottom-right-radius: 0">
			<div class="d-flex align-center" style="gap: 16px">
				<bod-autocomplete item-text="year"
								  item-value="year"
								  class="kpi-field"
								  hide-details
								  required
								  :readonly="isItemsLoading"
								  :loading="isPeriodsLoading"
								  style="width: 110px"
								  :items="uniqBy(periods, x => x.year)"
								  v-model="internalFilterValues.year"
								  color="blue"
								  :placeholder="$t('fields.kpiYear.placeholder')">
				</bod-autocomplete>
				<bod-autocomplete item-text="text"
								  item-value="value"
								  class="kpi-field"
								  hide-details
								  required
								  :readonly="isItemsLoading"
								  :loading="isPeriodsLoading"
								  style="width: 110px"
								  :items="quarters"
								  v-model="internalFilterValues.quarter"
								  color="blue"
								  :placeholder="$t('fields.kpiQuarter.placeholder')">
				</bod-autocomplete>
				<bod-autocomplete item-text="title"
								  item-value="id"
								  class="kpi-field"
								  @firstclick="fetchKpis"
								  hide-details
								  :readonly="isItemsLoading"
								  :loading="isKpisLoading"
								  style="width: 286px"
								  :items="availableKpis"
								  v-model="internalFilterValues.kpiId"
								  color="blue"
								  :placeholder="$t('fields.kpi.placeholder')">
				</bod-autocomplete>
				<bod-autocomplete item-text="fullName"
								  item-value="id"
								  class="kpi-field"
								  @firstclick="fetchUsers"
								  hide-details
								  multiple
								  :readonly="isItemsLoading"
								  :loading="isUsersLoading"
								  style="width: 276px"
								  :items="availableUsers"
								  v-model="internalFilterValues.staffIds"
								  color="blue"
								  :placeholder="$t('fields.employee.placeholder')">
				</bod-autocomplete>
				<v-spacer></v-spacer>
				<frp-btn @click="resetFilter"
						 :disabled="isFilterEmpty"
						 color="primary"
						 outlined
						 elevation="0">
					{{ $t("buttons.reset") }}
				</frp-btn>
				<frp-btn @click="applyFilter"
						 :disabled="!isFilterChanged || !internalFilterValues.year || !internalFilterValues.quarter"
						 no-margin
						 color="blue"
						 dark
						 elevation="0">
					{{ $t("buttons.accept") }}
				</frp-btn>
			</div>
		</v-card>
		
		<v-row>
			<v-col class="pb-0" style="border-top: 1px solid var(--v-grey-lighten4)">
				<v-form :readonly="isFormSaving || isFormReadonly"
						:disabled="isFormDisabled"
						:ref="refs.form"
						v-model="formValid">
					<v-data-table :headers="headers"
								  :loading-text="$t('tables.loading')"
								  :loading="isItemsLoading"
								  hide-default-footer
								  item-key="ids"
								  group-by="ids"
								  :items="formattedItems"
								  hide-default-header
								  :items-per-page="-1"
								  :mobile-breakpoint="mobileBreakpoint"
								  :options="options"
								  class="kpi-table clickable-rows d-flex flex-column kpi-shadow mt-4">
						<template #header="{ props: { headers } }">
							<colgroup class="kpi-colgroup">
								<col v-for="header in headers" span="1" :style="`width: ${header.width}`">
							</colgroup>
						</template>
						
						<template #group="{ items, headers }">
							<!--	GROUP	-->
							<tr class="v-row-group__header">
								<td class="pl-6 pr-2 py-4">
									<div class="blue--text text--darken-1 d-flex flex-column" style="font-size: 16px">
										<span style="font-size: 16px; line-height: 24px" class="font-weight-bold mb-1">
											{{ items[0].userName }}
										</span>
										<span style="font-size: 14px; line-height: 19px">{{ items[0].department.name }}</span>
										<span style="font-size: 14px; line-height: 19px">{{ items[0].position.title }}</span>
									</div>
								</td>
								
								<td colspan="4" class="px-2">
									<div class="d-flex align-center justify-space-between">
										<!--	КОВ	-->
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div v-bind="attrs" v-on="on" class="d-flex align-center">
													{{ $t("content.workingRate") }}:
													
													<div style="min-width: 48px">
														<span class="text-decoration-none font-weight-regular ml-1"
															  v-if="!isNil(items[0].workingRate)">
															{{ items[0].workingRate }}%
														</span>
													</div>
												</div>
											</template>
											
											<span class="px-2">{{ $t("tooltips.workingRate") }}</span>
										</v-tooltip>
										
										<!--	КТУ	-->
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div v-bind="attrs" v-on="on"
													 class="d-flex align-center text-decoration-none font-weight-regular px-2">
													{{ $t("content.laborRate") }}:
													
													<div style="min-width: 70px" class="d-flex align-center">
														<span class="ml-1" v-if="!isNil(items[0].workingRate)">
															<span v-if="checkIsEditable(items[0])">
																{{ editableItems[0].laborRate || 100 }}%
															</span>
															<span v-else>
																{{ items[0].laborRate?.value || 100 }}%
															</span>
														</span>
														
														<template v-if="checkIsEditable(items[0]) && canEditLaborRate(items[0].ids)">
															<frp-icon v-if="editableItems[0].laborRate"
																	  @click="handleOpenLaborRateDialog(items[0].ids)"
																	  width="20" height="20" class="ml-2" :color="colors.blue.darken4"
																	  src="ico_edit" style="cursor: pointer">
															</frp-icon>
															<v-icon v-else
																	@click="handleOpenLaborRateDialog(items[0].ids)"
																	size="20" class="ml-2" :color="colors.blue.darken4"
																	style="cursor: pointer">
																mdi-plus-circle-outline
															</v-icon>
														</template>
														<frp-information-menu v-else-if="items[0].laborRate?.justification"
																			  target-class="ml-2"
																			  :custom-color="colors.blue.base"
																			  :custom-hovered-color="colors.blue.lighten1"
																			  :text="items[0].laborRate.justification">
														</frp-information-menu>
													</div>
												</div>
											</template>
											
											<span>{{ $t("tooltips.laborRate") }}</span>
										</v-tooltip>
										
										<!--	ТЕСТИРОВАНИЕ	-->
										<div class="d-flex align-center px-2">
											{{ $t("content.testingRate") }}:
											<div style="min-width: 36px">
												<span class="text-decoration-none font-weight-regular ml-1"
													  v-if="!isNil(items[0].testingRate)">
													{{ items[0].testingRate }}%
												</span>
											</div>
										</div>
										
										<!--	ПРЕМИЯ	-->
										<div class="d-flex align-center text-decoration-none font-weight-regular px-2">
											{{ $t("content.finalAward") }}:
											
											<div style="min-width: 48px" class="ml-1">
												<span v-if="!isNil(items[0].bonus)">
													{{ items[0].bonus }}%
												</span>
											</div>
										</div>
									</div>
								</td>
							</tr>
							
							<!--	HEADER	-->
							<tr>
								<td v-for="(header, i) in headers" :key="header.text"
									class="px-2 py-3" :class="{ 'pl-9': i === 0, 'pr-9': i === headers.length - 1 }">
									{{ header.text }}
								</td>
							</tr>
							<!--	BODY	-->
							<tr v-for="(item, i) in items.filter(x => x.userId)" :key="item.ids + i">
								<td v-for="(header, j) in headers" :key="header.value"
									class="px-2 py-3" :class="{ 'pl-9': j === 0, 'pr-9': j === headers.length - 1 }">
									<span v-if="header.value === 'kpiName'" class="text-decoration-underline">{{ item.kpiName }}</span>
									<template v-else-if="header.value === 'criterias'">
										<frp-radio-group v-if="checkIsEditable(item)"
														 color="blue"
														 mandatory
														 item-class="pa-0"
														 wrapper-style="gap: 10px; flex-direction: column"
														 :value="editableItems.find(x => x.kpiId === item.kpiId).selectedCriteriaId"
														 @update:value="setEditableItemSelectedCriteriaId({ id: item.kpiId, value: $event })"
														 :items="item.criterias.map(x => ({ label: x.title, value: x.id }))">
										</frp-radio-group>
										<span v-else-if="checkIsCompleted(item)">
											{{ item.criterias.find(x => x.isCompleted).title }}
										</span>
										<kpi-kpis-array-item v-else :item="item.criterias.map(x => x.title)"/>
									</template>
									<template v-else-if="header.value === 'completion'">
										<frp-text-field v-if="checkIsEditable(item)"
														required
														:rules="validation.completion"
														style="max-width: 170px"
														class="kpi-field kpi-dense-field"
														:value="editableItems.find(x => x.kpiId === item.kpiId).completion"
														@update:value="setEditableItemCompletion({ id: item.kpiId, value: $event })"
														type="number"
														hide-details
														:placeholder="$t('fields.kpiCompletion.placeholder')">
										</frp-text-field>
										<span v-else-if="!isNil(item.criterias.find(x => x.isCompleted)?.completion)">
											{{ item.criterias.find(x => x.isCompleted)?.completion }} %
										</span>
									</template>
									<template v-else-if="header.value === 'justification'">
										<frp-textarea v-if="checkIsEditable(item)"
													  class="kpi-field"
													  :required="parseFloat(editableItems.find(x => x.kpiId === item.kpiId).completion) !== fullCompletion"
													  :value="editableItems.find(x => x.kpiId === item.kpiId).justification"
													  @update:value="setEditableItemJustification({ id: item.kpiId, value: $event })"
													  hide-details
													  :placeholder="$t('fields.kpiJustification.placeholder')">
										</frp-textarea>
										<frp-textarea v-else-if="item.criterias.find(x => x.isCompleted)?.justification"
													  class="kpi-field"
													  readonly hide-details
													  :value="item.criterias.find(x => x.isCompleted).justification">
										</frp-textarea>
									</template>
									<kpi-kpis-array-item v-else-if="header.value === 'weights'" :item="item.weights"/>
									<span v-else>{{ item[header.value] }}</span>
								</td>
							</tr>
							<!--	FOOTER	-->
							<tr>
								<td class="px-9 py-3" colspan="5">
									<template v-if="!checkIsEditable(items[0]) && checkIsAllCompleted(items)">
										<frp-btn @click="handleReport(items[0].ids)"
												 v-if="canEditReport(items[0].ids)"
												 color="blue" dark elevation="0">
											{{ $t("buttons.edit") }}
										</frp-btn>
										<frp-btn @click="handleOpenEvaluationReportDialog(items[0].ids)"
												 v-if="canReadEvaluationReport(items[0].ids)"
												 color="primary" dark elevation="0" outlined>
											{{ $t("buttons.evaluationReport") }}
										</frp-btn>
									</template>
									
									<frp-btn @click="handleReport(items[0].ids)"
											 v-else-if="!checkIsEditable(items[0]) && canReport(items[0].ids)"
											 color="blue" dark elevation="0">
										{{ $t("buttons.report") }}
									</frp-btn>
									
									<template v-else-if="checkIsEditable(items[0])">
										<frp-btn @click="resetEditableItem"
												 color="grey lighten-3" dark elevation="0">
											{{ $t("buttons.cancel") }}
										</frp-btn>
										<frp-btn @click="save"
												 :loading="isFormSaving"
												 :disabled="!stateContainsUnsavedChanges || !isFormValid"
												 color="blue" dark elevation="0">
											{{ $t("buttons.save") }}
										</frp-btn>
									</template>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-form>
				
				<kpi-reporting-labor-rate-dialog v-model="isLaborRateDialogOpened"
												 v-if="openedDialogIds"
												 @save="handleSaveLaborRate"
												 :user="formattedItems.find(x => x.ids === openedDialogIds)"
												 :editable="editableItems.find(x => x.ids === openedDialogIds)"/>
				
				<kpi-download-score-cards-dialog v-model="isDownloadScoreCardsDialogOpened" v-if="isDownloadScoreCardsDialogOpened"/>
				
				<kpi-evaluation-report-dialog v-model="isEvaluationReportDialogOpened"
											  v-if="isEvaluationReportDialogOpened"
											  :user="formattedItems.find(x => x.ids === openedDialogIds)"/>
				
				<frp-confirm-dialog v-if="isUnsavedReportDialogOpened"
									@submit="handleReport"
									@cancel="isUnsavedReportDialogOpened = false"
									:cancel-btn="$t('buttons.cancel')"
									:title="$t('dialogs.confirmCancelFormChanges.title')"
									:description="$t('dialogs.confirmCancelFormChanges.description')">
				</frp-confirm-dialog>
				
				<frp-bottom-space height="20"></frp-bottom-space>
			</v-col>
		</v-row>
		
		<template #footer>
			<bod-footer></bod-footer>
		</template>
	</kpi-content-layout>
</template>

<script>
import { prepareMaxNumbersRule, prepareMinNumbersRule, requiredRule } from "@/utils/validation";
import KpiDownloadScoreCardsDialog from "@/views/kpi/reporting/KpiDownloadScoreCardsDialog.vue";
import KpiEvaluationReportDialog from "@/views/kpi/reporting/KpiEvaluationReportDialog.vue";
import FrpInformationMenu from "Components/common/FrpInformationMenu";
import FrpPagination from "Components/common/FrpPagination";
import FrpConfirmDialog from "Components/dialogs/FrpConfirmDialog";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import FrpCheckboxGroup from "Components/fields/FrpCheckboxGroup";
import FrpRadioGroup from "Components/fields/FrpRadioGroup";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpIcon from "Components/icon/FrpIcon";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import KpiContentLayout from "Components/layouts/KpiContentLayout";
import { getQuarter } from "date-fns";
import { assign, isEqual, last } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import formMixin from "Mixins/formMixin";
import { RouteNames } from "Router/kpi/routes";
import { namespace } from "Store/kpi/modules/reporting";
import { actionTypes, getterTypes, mutationTypes } from "Store/kpi/modules/reporting/types";
import ReportingFilter from "Store/kpi/modules/reporting/types/reportingFilter";
import { KpiQuarterEnum } from "Types/kpi/KpiQuarterEnum";
import { ApiKpiQuarterEnum } from "Api/kpi/types/ApiKpiQuarterEnum";
import KpiKpisArrayItem from "Views/kpi/kpis/KpiKpisArrayItem";
import KpiReportingLaborRateDialog from "Views/kpi/reporting/KpiReportingLaborRateDialog";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import FrpAlert from "Components/alerts/FrpAlert";
import FrpAlerts from "Components/alerts/FrpAlerts";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpTextField from "Components/fields/FrpTextField";
import BodFooter from "Components/layouts/BodFooter";
import BodMain from "Components/layouts/BodMain";
import { uniqBy, isNil } from "lodash";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage, authorizationMixin, formMixin],
	metaInfo() {
		return {
			title: this.title
		};
	},
	data() {
		return {
			RouteNames,
			namespace,
			uniqBy,
			isNil,
			newEditableItemIds: "",
			openedDialogIds: "",
			isUnsavedReportDialogOpened: false,
			isLaborRateDialogOpened: false,
			isDownloadScoreCardsDialogOpened: false,
			isEvaluationReportDialogOpened: false,
			fullCompletion: 100,
			validation: {
				completion: [
					requiredRule(),
					prepareMinNumbersRule({ getMin: () => 0 }),
					prepareMaxNumbersRule({ getMax: () => 100 })
				]
			},
			headers: [
				{
					text: this.$t("tables.kpi"),
					value: "kpiName",
					width: "38%",
					sortable: false
				},
				{
					text: this.$t("tables.criteria"),
					value: "criterias",
					width: "15%",
					sortable: false
				},
				{
					text: this.$t("tables.weightInPercents"),
					value: "weights",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.completion"),
					value: "completion",
					width: "15%",
					sortable: false
				},
				{
					text: this.$t("tables.justification"),
					value: "justification",
					width: "22%",
					sortable: false
				}
			],
			internalFilterValues: {
				year: null,
				quarter: null,
				kpiId: "",
				staffIds: []
			}
		};
	},
	computed: {
		...mapState({
			initialized: state => state.isInitialized,
			isKpisLoading: state => state.isKpisLoading,
			isUsersLoading: state => state.isUsersLoading,
			isPeriodsLoading: state => state.isPeriodsLoading,
			periods: state => state.periods,
			editableItems: state => state.editableItems,
			reportingPermissions: state => state.permissions,
			isPermissionsLoading: state => state.isPermissionsLoading,
			downloadingScoreCardUserIds: state => state.downloadingScoreCardUserIds
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty,
			availableUsers: getterTypes.availableUsers,
			availableKpis: getterTypes.availableKpis
		}),
		filter() {
			return {
				year: this.internalFilterValues.year,
				quarter: this.internalFilterValues.quarter,
				kpiId: this.internalFilterValues.kpiId,
				staffIds: this.internalFilterValues.staffIds
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v === null ? [] : v])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			if(!this.periods.length) return true;
			return isEqual(new ReportingFilter(last(this.periods).year, String(getQuarter(new Date()))), this.filterValues);
		},
		title() {
			if(this.filterValues.year)
				return this.filterValues.quarter !== KpiQuarterEnum.YEAR ?
					this.$t("metaTitles.reportingByQuarter",
						{ year: this.filterValues.year, quarter: ApiKpiQuarterEnum[this.filterValues.quarter] }) :
					this.$t("metaTitles.reportingByYear", { year: this.filterValues.year });
			else
				return this.$t("metaTitles.reporting");
		},
		quarters() {
			const items = this.periods.filter(x => x.year === this.internalFilterValues.year).map(x => x.quarter ?
				String(x.quarter) :
				KpiQuarterEnum.YEAR);
			
			return items.map(x => ({ text: this.$t(`aliases.kpiQuarter.${x}`), value: x }));
		},
		itemsByUsers() {
			return uniqBy(this.formattedItems, x => x.ids);
		},
		canReadEvaluationReportsArchive() {
			return this.permissions.includes(this.Permissions.KPI_REPORT_EVALUATION_DOWNLOAD_ARCHIVE);
		}
	},
	methods: {
		...mapMutations({
			setFilterYear: mutationTypes.SET_FILTER_YEAR,
			setFilterQuarter: mutationTypes.SET_FILTER_QUARTER,
			setFilterKpiId: mutationTypes.SET_FILTER_KPI_ID,
			setFilterStaffIds: mutationTypes.SET_FILTER_STAFF_IDS,
			resetFilter: mutationTypes.RESET_FILTER,
			setEditableItemSelectedCriteriaId: mutationTypes.SET_EDITABLE_ITEM_SELECTED_CRITERIA_ID,
			setEditableItemCompletion: mutationTypes.SET_EDITABLE_ITEM_COMPLETION,
			setEditableItemJustification: mutationTypes.SET_EDITABLE_ITEM_JUSTIFICATION,
			resetEditableItem: mutationTypes.RESET_EDITABLE_ITEMS,
			setEditableItems: mutationTypes.SET_EDITABLE_ITEMS
		}),
		...mapActions({
			fetchUsers: actionTypes.fetchUsers,
			fetchKpis: actionTypes.fetchKpis,
			updateEditableItems: actionTypes.updateEditableItems,
			save: actionTypes.save
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		},
		applyFilter() {
			const isYearUpdated = this.internalFilterValues.year !== this.filterValues.year;
			const isQuarterUpdated = this.internalFilterValues.quarter !== this.filterValues.quarter;
			
			if(isYearUpdated)
				this.setFilterYear(this.internalFilterValues.year || last(this.periods).year);
			if(isQuarterUpdated)
				this.setFilterQuarter(this.internalFilterValues.quarter || last(this.periods).quarter);
			
			if(!isYearUpdated && !isQuarterUpdated) {
				this.setFilterKpiId(this.internalFilterValues.kpiId);
				this.setFilterStaffIds(this.internalFilterValues.staffIds || []);
			}
		},
		checkIsEditable({ ids }) {
			return this.editableItems.some(x => x.ids === ids);
		},
		checkIsCompleted(item) {
			return item.criterias.some(y => y.isCompleted);
		},
		checkIsAllCompleted(items) {
			return items.every(x => x.criterias.some(y => y.isCompleted));
		},
		async handleReport(ids) {
			if(!ids) {
				await this.updateEditableItems(this.newEditableItemIds);
				this.newEditableItemIds = "";
				this.isUnsavedReportDialogOpened = false;
			} else {
				if(!this.editableItems.length)
					await this.updateEditableItems(ids);
				else {
					this.newEditableItemIds = ids;
					this.isUnsavedReportDialogOpened = true;
				}
			}
		},
		handleOpenLaborRateDialog(ids) {
			this.openedDialogIds = ids;
			this.isLaborRateDialogOpened = true;
		},
		handleOpenEvaluationReportDialog(ids) {
			this.openedDialogIds = ids;
			this.isEvaluationReportDialogOpened = true;
		},
		handleSaveLaborRate({ justification: laborRateJustification, laborRate }) {
			this.setEditableItems(this.editableItems.map(x => ({ ...x, laborRate, laborRateJustification })));
			this.openedDialogIds = "";
		},
		canEditLaborRate(ids) {
			return this.reportingPermissions.find(x => x.ids === ids)?.permissions
					   .includes(this.Permissions.KPI_REPORT_LABOR_RATE_UPDATE);
		},
		canEditReport(ids) {
			return this.reportingPermissions.find(x => x.ids === ids)?.permissions.includes(this.Permissions.KPI_REPORT_UPDATE);
		},
		canReadEvaluationReport(ids) {
			return this.reportingPermissions.find(x => x.ids === ids)?.permissions
					   .includes(this.Permissions.KPI_REPORT_EVALUATION_REPORT_READ);
		},
		canReport(ids) {
			return this.reportingPermissions.find(x => x.ids === ids)?.permissions.includes(this.Permissions.KPI_REPORT_CREATE);
		}
	},
	watch: {
		"internalFilterValues.year"() {
			if(this.quarters.length)
				this.internalFilterValues.quarter = this.quarters[0].value;
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		KpiEvaluationReportDialog,
		KpiDownloadScoreCardsDialog,
		FrpInformationMenu,
		KpiReportingLaborRateDialog,
		FrpConfirmDialog,
		FrpTextarea,
		FrpRadioGroup,
		FrpCheckboxGroup,
		FrpBottomSpace,
		KpiContentLayout,
		BodAutocomplete,
		FrpPagination,
		FrpIcon,
		FrpBtn,
		FrpCheckbox,
		FrpTextField,
		FrpAutocomplete,
		BodMain,
		FrpAlerts,
		FrpAlert,
		BodFooter,
		KpiKpisArrayItem
	}
};
</script>
