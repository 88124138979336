import { mutationTypes, actionTypes } from "@/store/shared/route/types";
import { MutationTree } from "vuex";
import RouteState from "@/store/shared/route/types/routeState";

export default class RouteMixinBuilder<T extends { route: RouteState }> {
	constructor() {

	}

	build() {
		return {
			state: () => new RouteState(false, false),
			mutations: <MutationTree<T>>{
				[mutationTypes.SET_IS_ROUTE_PROCESSING](state, value) {
					state.route.isProcessing = value;
				},
				[mutationTypes.SET_IS_ROUTE_PUSHING](state, value) {
					state.route.isPushing = value;
				}
			},
			actions: {}
		};
	}
}
