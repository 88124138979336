export default class PagingModel {
	total: number;
	page: number;
	pageSize: number;

	constructor({total, page, pageSize}: { total: number, page: number, pageSize: number }) {
		this.total = total;
		this.page = page;
		this.pageSize = pageSize;
	}
}
