import { convertToTimestamp } from "@/utils/dates";
import { ApiDelegacyItem } from "@/api/kpi/types/apiDelegacyItem";
import { ApiKpiUser } from "@/api/kpi/types/apiKpiUser";

export interface DelegacyItem {
	id: string;
	date: number;
	appointer: ApiKpiUser;
	attorney: ApiKpiUser;
	isDeleted: boolean;
}

export class DelegacyItemMapper {
	static map(source: ApiDelegacyItem): DelegacyItem {
		return {
			...source,
			date: convertToTimestamp(source.createdAt) as number
		};
	}
}
