import { IsEnum, IsString } from "class-validator";
import { DelegacyStatusEnum } from "@/types/kpi/delegacyStatusEnum";

export default class DelegacyFilter {
	@IsString()
	departmentId: string;
	
	@IsString()
	appointerId: string;
	
	@IsEnum(() => DelegacyStatusEnum)
	status: DelegacyStatusEnum;

	constructor(
		departmentId: string = "",
		appointerId: string = "",
		status: DelegacyStatusEnum = DelegacyStatusEnum.ACTIVE
	)
	{
		this.departmentId = departmentId;
		this.appointerId = appointerId;
		this.status = status;
	}
}
