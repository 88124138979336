import { render, staticRenderFns } from "./FrpFormCardBlock.vue?vue&type=template&id=d091dda2&scoped=true"
import script from "./FrpFormCardBlock.vue?vue&type=script&lang=js"
export * from "./FrpFormCardBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d091dda2",
  null
  
)

export default component.exports