import { IsArray, IsString } from "class-validator";

export default class ReportingRouteQuery {
	@IsString()
	kpiId: string;
	
	@IsArray()
	staffIds: string[];
	
	constructor(
		kpiId: string = "",
		staffIds: string[] = []
	)
	{
		this.kpiId = kpiId;
		this.staffIds = staffIds;
	}
}
