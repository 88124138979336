<template>
	<frp-dialog :value="value"
				@update:value="$emit('update:value', $event)"
				max-width="521"
				:title="user.userName"
				persistent>
		<template #content="{ onIntersect }">
			<v-form ref="form" v-intersect="onIntersect" v-model="formValid">
				<frp-text-field :label="$t('fields.kpiLaborRate.label')"
								v-model="formValues.laborRate"
								type="number"
								required
								:placeholder="$t('fields.kpiLaborRate.placeholder')">
				</frp-text-field>
				<frp-textarea :required="formValues.laborRate !== '100'"
							  rows="7"
							  :autoGrow="false"
							  v-model="formValues.justification"
							  :label="$t('fields.kpiJustification.label')"
							  :placeholder="$t('fields.kpiJustification.placeholder')">
				</frp-textarea>
			</v-form>
		</template>
		
		<template #footer>
			<frp-btn outlined
					 @click="$emit('update:value', false)"
					 color="primary">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0"
					 color="blue"
					 dark
					 :disabled="!stateContainsUnsavedChanges || !formValid"
					 @click="handleSave">
				{{ $t("buttons.save") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpTextField from "Components/fields/FrpTextField";
import { formatFullName } from "Utils/formatting";

export default {
	components: { FrpBtn, FrpTextarea, FrpTextField, FrpDialog },
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		user: Object,
		editable: Object
	},
	data() {
		return {
			formatFullName,
			formValid: false,
			formValues: {
				laborRate: "",
				justification: ""
			}
		};
	},
	computed: {
		stateContainsUnsavedChanges() {
			return this.formValues.laborRate !== (this.user.laborRate?.value || "") || this.formValues.justification !==
				(this.user.laborRate?.justification || "");
		}
	},
	methods: {
		handleSave() {
			this.$emit("save", this.formValues);
			this.$emit("update:value", false);
		}
	},
	watch: {
		value: {
			handler(val) {
				if(val) {
					this.formValues = {
						laborRate: this.editable.laborRate || this.user.laborRate?.value || "",
						justification: this.editable.laborRateJustification || this.user.laborRate?.justification || ""
					};
					if(this.$refs.form)
						this.$refs.form.resetValidation();
				}
			},
			immediate: true
		}
	}
};
</script>
