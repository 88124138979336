import { ApiKpiPeriod } from "@/api/kpi/types/apiKpiPeriod";
import { getIds } from "@/store/kpi/helpers/staffDepartmentPosition";
import { ApiStaffKpi } from "@/api/kpi/types/apiStaffKpi";
import { ApiKpiUser } from "@/api/kpi/types/apiKpiUser";

export interface AddKpiStaff {
	staff: ApiKpiUser;
	period: ApiKpiPeriod;
	isChecked: boolean;
	hasReport: boolean;
	ids: string;
}

export class AddKpiStaffHelper {
	static mapFromApiStaffKpi(source: ApiStaffKpi): AddKpiStaff {
		return {
			...source,
			period: { year: source.period.year, quarter: source.period.quarter ?? null },
			ids: getIds(source.staff.id, source.staff.department.id, source.staff.position.id)
		};
	}
}
