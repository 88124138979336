import { RouteNames } from "@/router/kpi/routes";
import { Permissions } from "@/constants/permissions";

const routeToPermissionsMap = new Map<RouteNames, Permissions[]>([
		[RouteNames.ROOT, []],
		[RouteNames.CALLBACK, []],
		[RouteNames.SILENT_RENEW, []],
		[RouteNames.ERROR_PAGE_NOT_FOUND, []],
		[RouteNames.ERROR_ACCESS_DENIED, []],
		[RouteNames.ERROR_INTERNAL_SERVER_ERROR, []],

		[RouteNames.KPIS, [Permissions.KPI_REGISTRY_READ]],
		[RouteNames.REPORTING, [Permissions.KPI_REPORT_READ]],
		[RouteNames.KPI, []],
		[RouteNames.KPI_CREATE, [Permissions.KPI_KPI_CREATE]],
		[RouteNames.DELEGACY, [Permissions.KPI_DELEGACY_READ]]
	]
);

export default routeToPermissionsMap;
