import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import KpisFilter from "@/store/kpi/modules/kpis/types/kpisFilter";
import { ApiKpiUser } from "@/api/kpi/types/apiKpiUser";
import { ApiKpiDepartment } from "@/api/kpi/types/apiKpiDepartment";
import { ApiKpiPeriod } from "@/api/kpi/types/apiKpiPeriod";
import { ApiKpisItem } from "@/api/kpi/types/apiKpisItem";

export default class KpisState implements IPageState {
	constructor(
		public listing: ListingModel<ApiKpisItem>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public filter: KpisFilter,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDepartmentsLoading: boolean = false,
		public isUsersLoading: boolean = false,
		public isPeriodsLoading: boolean = false,
		public isExcelDownloading: boolean = false,
		public departments: ApiKpiDepartment[] = [],
		public users: ApiKpiUser[] = [],
		public periods: ApiKpiPeriod[] = []
	)
	{
	}
}
