import { ApiKpiPeriod } from "@/api/kpi/types/apiKpiPeriod";

export interface KpiPeriod {
	year: number;
	quarter: number | null;
}

export class KpiPeriodMapper {
	static map(source: ApiKpiPeriod): KpiPeriod {
		return {
			year: source.year,
			quarter: source.quarter || null
		};
	}
}