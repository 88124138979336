import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import ApiFile from "@/api/types/storage/apiFile";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { prepareFormData } from "@/utils/prepareFormData";
import { getFormDataHeaders } from "@/utils/getFormDataHeaders";
import { plainToInstance } from "class-transformer";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import ApiCertificate from "@/api/types/signature/apiCertificate";

export class KpiStorageController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	createTemperFile = async (file: ApiFile): Promise<ApiFileMeta> => {
		const url = file.signature ? urls.kpi.storage.file.createTemperSigned : urls.hr.storage.file.createTemper;
		
		let result = await this.client.post<object>(url, prepareFormData(file), getFormDataHeaders());
		
		return plainToInstance(ApiFileMeta, result);
	};
	
	getTemperFile = async (id: string) => {
		return this.client.get<Blob>(prepareUrl(urls.kpi.storage.file.tempDownload.replace(urlTemplateParts.id, id)),
			{ responseType: "blob" });
	};
	
	getFile = async (id: string) => {
		return this.client.get<Blob>(prepareUrl(urls.kpi.storage.file.download.replace(urlTemplateParts.id, id)),
            { responseType: "blob" });
	};
	
	getFileMeta = async (id: string) => {
		let result = await this.client.get<object>(prepareUrl(urls.kpi.storage.file.getMeta.replace(urlTemplateParts.id, id)));
		
		return plainToInstance(ApiFileMeta, result);
	};
	
	/**
	 * @param signature Подпись в base64
	 */
	getSignerCertificate = async (signature: string): Promise<ApiCertificate[]> => {
		return await this.client.post<any>(urls.kpi.storage.cryptopro.getSignerCertificates, { signature });
	};
}
