export const getterTypes = {};

export const actionTypes = {
	handleServerError: "handleServerError"
};

export const mutationTypes = {
	SET_IS_APP_LOADING: "SET_IS_APP_LOADING",
	SET_PAGE_MODE: "SET_PAGE_MODE",
	RESET_PAGE_MODE: "RESET_PAGE_MODE",
	SET_PAGE_MODE_NOT_FOUND: "SET_PAGE_MODE_NOT_FOUND",
	SET_PAGE_MODE_ACCESS_FORBIDDEN: "SET_PAGE_MODE_ACCESS_FORBIDDEN"
};

const rootTypes = {
	actionTypes, mutationTypes, getterTypes
};

export default rootTypes;
